<template>
  <EnvelopeHeaderLarge />

<div class="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-5">

        <div class="home-login">

          <div class="envelope-header envelope-header--home">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="envelope-header__inner">
                    <img class="envelope-header__logo" src="/src/shared/img/VIL-logo-black.svg" alt="VIL Logo">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="home-login__form">
            <LoginForm
                @get-title="setFormTitle"
            />

            <div v-if="text" class="home-login__register">
              <span>{{ text.notMemberLabel }}</span>
              <router-link :to="{ name: 'Register', params: { lang: language } }">
                <Button
                    :buttonLabel="text.registerButtonLabel"
                    :buttonStyle="'outline'"
                    :buttonColor="'transparent'"
                    :buttonSize="'small'"
                />
              </router-link>
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-7">
        <AboutSlider />
      </div>

    </div>
  </div>
</div>

</template>

<script>
import AboutSlider from "@/components/AboutSlider";
import EnvelopeHeaderLarge from "@/components/layout/EnvelopeHeaderLarge";
import LoginForm from "@/components/form/LoginForm";

export default {
  name: 'Home-page',
  components: {
    EnvelopeHeaderLarge,
    AboutSlider,
    LoginForm
  },
  data() {
    return {
      formTitle: null,
      text: null
    }
  },
  computed: {
    language() {
      return this.$store.getters['language/getLanguage'];
    }
  },
  methods: {

    async ajaxGetText() {
      return await this.$api.text.get('user')
          .then(data => {

            this.text = data.data;

            // console.log(data)
          })
          .catch(error => {
            console.log(error);
          })
    },
    changeRoute() {
      this.$router.push('/dashboard')
    },
    setFormTitle(title) {
      this.formTitle = title;
    }
  },
  created() {
    this.ajaxGetText();
  }
}
</script>

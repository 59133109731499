<template v-if="user">
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <Form v-if="profile"
              :ajaxPath="'user/profile'"
              :propForm="profile"
              @get-title="emitTitle"
              @post-success="$router.push({ name: 'Dashboard' })"
          />

          <div class="account-delete">
            <div class="account-delete__button">
                <Button v-if="profile" @click="openDeletePopup(user.id)"
                        :buttonLabel="profile.deleteButtonLabel"
                        :buttonStyle="'red'"
                />
            </div>
            <router-link v-if="profile" :to="{ name: 'Privacy' }">
              {{ profile.privacyLabel }}
            </router-link>
            <router-link v-if="profile" :to="{ name: 'Terms' }">
              {{ profile.termsLabel }}
            </router-link>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="popup location-delete-popup"
       :class="{ 'popup--hidden': !popupVisible }">
    <div class="container">
      <div class="row justify-content-around">
        <div class="col-lg-6">
          <div class="popup__inner">

            <h3 v-if="profile">{{ profile.confirmModalTitle }}</h3>
            <p v-if="profile">{{ profile.confirmModalBody }}</p>
            <br>
            <Button v-if="profile"
                    @click="closeDeletePopup"
                    :buttonLabel="profile.confirmModalCancel"
                    :buttonStyle="'transparent'"
                    :buttonSize="'small'"
            />
            <Button v-if="profile"
                    @click="deleteAccount()"
                    :buttonLabel="profile.confirmModalOk"
                    :buttonStyle="'red'"
                    :buttonSize="'small'"
            />

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Form from "@/components/form/Form";

export default {
  name: 'profile-form',
  components: {
    Form
  },
  data() {
    return {
      user: null,
      profile: null,
      popupVisible: false,
      deleteId: null,
    }
  },
  created() {
    this.ajaxGetUser();
    this.ajaxGetProfile();
  },
  methods: {

    async ajaxGetUser() {
      return await this.$api.user.get()
          .then(data => {

            this.user = data.data;

            // console.log('Get successful');
            this.loading = false;
          });
    },

    async ajaxGetProfile() {
      return await this.$api.form.get('user/profile')
          .then(data => {

            this.profile = data;

            // console.log('Get successful');
            this.loading = false;
          });
    },

    /**
     * Delete the account
     *
     * @returns {Promise<Response>}
     */
    async deleteAccount() {
      return await this.$api.user.delete()
          .then(data => {

            this.$auth.isAuthenticated = '0';

            if (data.data.message) {
              this.$store.dispatch('message/setMessage', data.data.message);
            }

            this.$router.push({ name: 'Home' });
            // console.log('Post successful');

            this.loading = false;
          });
    },

    openDeletePopup(id) {
      this.popupVisible = true;
      this.deleteId = id;
    },

    closeDeletePopup() {
      this.popupVisible = false;
    },

    emitTitle(title) {

      this.$emit('get-title', title);
    }
  }
}
</script>
<template>
  <div class="envelope-header envelope-header--large">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="envelope-header__inner">
            <img class="envelope-header__logo" src="/src/shared/img/vil-logo.png" alt="VIL Logo">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['pageTitle'],
  name: 'EnvelopeHeaderLarge',
}
</script>

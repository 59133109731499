<template>
  <div class="location-item__location__inner">
    <div class="location-item__location__index">
      {{ index + 1 }}
    </div>
    <div>
      <p><strong>{{ location.title }}</strong></p>

      <p v-if="user.data && user.data.account.type == organisationTypeId">
        Key: {{ location.code }}
      </p>
    </div>
  </div>
  <div
       class="location-item__location__edit">
    <Icon @click="locationOptions(location.id)"
          :iconColor="'transparent'"
          :iconSize="'small'"
          :iconClass="'pencil'"
    />
    <div @click="locationOptions(location.id)"
         class="location-item__location__popup location-item__location__popup--hidden"
         ref="location">
      <!--              <router-link :to="{ name: 'EditLocation', params: { hashId: location.id } }">-->
      <!--                {HC} Adres aanpassen-->
      <!--              </router-link>-->
      <a @click="emitToggleModal(location.id, 'location/form')">
        {{ changeLocation }}
      </a>
      <a @click="emitDeleteLocation(location.id)">
        {{ deleteLocation }}
      </a>
    </div>
  </div>

</template>

<script>

export default {
  name: 'Location-item',
  data() {
    return {
      folded: false,
      locations: {},
      changeLocation:`${changeLocation}`, // eslint-disable-line
      deleteLocation:`${deleteLocation}`, // eslint-disable-line
      organisationTypeId: `${organisationTypeId}`
    }
  },
  props: [
    'location',
    'index',
    'user'
  ],
  methods: {

    /**
     * Route to the newLocation form, also send the addressId with it in the router params
     */
    newLocation() {
      this.$router.push({ name: 'NewLocation', params: { hashId: this.addressData.id } });
    },

    foldLocationItem() {
      this.folded = ! this.folded;
    },
    showGeoLocation() {
      this.showAll = true;
    },

    locationOptions() {
      this.$refs.location.classList.toggle('location-item__location__popup--hidden');
    },

    emitToggleModal(id, path) {
      let params = this.generateUrlParameters(id)
      this.$emit('toggleModal', id, path, params);
    },

    emitDeleteLocation(id) {
      this.$emit('deleteLocation', id);
    },

    /**
     * Generate a custom parameter string to add to the requests
     *
     * @param id
     * @returns {string}
     */
    generateUrlParameters(id) {
      return '?id=' + id;
    },
  }
}
</script>
import { ApiConfig } from '../ApiConfig.js';
import store from "@/store";

/**
 * VacancyRoutes
 *
 * All available requests for getting vacancy periods from the backend
 */
export default {

    /**
     * ALL
     *
     * @param locale
     * @returns {Promise<Response>}
     */
    all(locale = '') {

        // check if a locale is set
        if (store.getters['language/getLanguage']) {
            locale = store.getters['language/getLanguage'];
        }

        return fetch(ApiConfig.baseURL + '/' + locale + '/ajax/vacation-period/get-all', {
            method: 'GET',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    },



    /**
     * DELETE
     *
     * @param id
     * @returns {Promise<Response>}
     */
    delete(id) {
        return fetch(ApiConfig.baseURL + '/ajax/vacation-period/delete/' + id, {
            method: 'POST',
            headers: ApiConfig.headers
        })
            .then(function (response) {
                if (response.status !== 200) {
                    throw response.status;
                } else {
                    return response.json();
                }
            });
    }
}
<template>
    <div>
        <EnvelopeHeader :page-title="title" />

        <EditVacationPeriodForm
            @get-title="setTitle"
        />
    </div>
</template>

<script>

import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import EditVacationPeriodForm from "@/components/form/EditVacationPeriodForm";

export default {
    name: 'edit-vacation-period',
    data() {
        return {
            title: null
        }
    },
    components: {
        EditVacationPeriodForm,
        EnvelopeHeader,
    },
    methods: {

        setTitle(title) {
            this.title = title;
        }
    }
}
</script>

<template>

    <div v-for="option in inputAttributes['options']" :key="option.id">
        <div class="vacation-option">
            <VacationOption
                :option="option"
                :locations="locations"

                @add-location="addLocation"
                @remove-location="removeLocation"
            />
        </div>
    </div>

    <p class="input-error">{{ inputError }}</p>

</template>

<script>
import VacationOption from "@/components/form/inputs/custom/vacation/VacationOption";
export default {
    name: 'Input-location-checkboxes',
    components: {
        VacationOption
    },
    data() {
        return {
            locations: [],
            options: {}
        }
    },
    props: [
        'inputId',
        'inputRequired',
        'inputValue',
        'inputError',
        'inputHidden',
        'inputAttributes'
    ],
    methods: {

        addLocation(id) {

            this.locations.push(id);
            this.$emit('update-input', this.createUpdateObject());
        },

        removeLocation(id) {

            const index = this.locations.indexOf(id);

            if (index > -1) {
                this.locations.splice(index, 1);
            }

            this.$emit('update-input', this.createUpdateObject());
        },

        createUpdateObject() {

            return {
                value: this.locations,
                id: this.inputId,
                dataset: null
            }
        }
    }
}
</script>
<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <Form
              :ajaxPath="'user/forgot-password'"
              @post-success="redirect"
              @get-title="emitTitle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
  name: 'password-form',
  components: {
    Form
  },
  methods: {

    redirect() {

      // go to our dashboard
      this.$router.push({ name: 'Home' });
    },

    emitTitle(title) {

      this.$emit('get-title', title);
    }
  },
}
</script>
<template>
  <div>
    <EnvelopeHeader :page-title="title" />

    <AddLocationForm />

  </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import AddLocationForm from "@/components/form/AddLocationForm";

export default {
  name: 'register-page',
  data() {
    return {
      title: null
    }
  },
  components: {
    AddLocationForm,
    EnvelopeHeader
  },
  methods: {

    setTitle(title) {
      this.title = title;
    }
  }
}
</script>

<template>

    <div v-if="notifications && notifications.length" class="envelope-header__options__profile">
        <Icon @click="notificationsOptions"
              :iconColor="'dark'"
              :iconClass="'notification'"
              :mark="mark"
        />

        <div class="tooltip tooltip--header tooltip--notification tooltip--hidden" ref="profileOptions">

            <div class="tooltip-notification-heading">
                <div>{{ notificationLabel }}</div>
                <a @click="readAll"><i class="icon-check-all"></i> {{ notificationsReadLabel }}</a>
            </div>

            <div v-for="notification in notifications" :key="notification.id" class="tooltip-notification-item">
                <div class="tooltip-notification">
                    <a @click="openNotification(notification.id)">
                        <span v-if=" ! notification.isSeen" class="tooltip-notification__unseen"></span>
                        {{ notification.title }}
                    </a>
                    <a @click="deleteNotification(notification.id)" class="tooltip-notification__delete"><i class="icon-trash"></i></a>
                </div>
                <span class="tooltip-notification__date">{{ notification.date }}</span>
            </div>
        </div>
    </div>

    <NotificationComponent :activeId="activeComponentId" @close-notification="resetNotifications" />

</template>

<script>

import NotificationComponent from "@/components/layout/partials/Notification";
export default {
    name: 'NotificationsComponent',
    components: {NotificationComponent},
    data() {
        return {
            notifications: null,
            notificationLabel: null,
            notificationsReadLabel: null,
            toggle: null,
            mark: false,
            activeComponentId: null
        }
    },
    watch: {
        toggle() {
            this.$refs.profileOptions.classList.toggle('tooltip--hidden');
        },
        activeComponent() {

        }
    },
    methods: {

        async ajaxGetNotifications() {

            return await this.$api.notification.all()
                .then(data => {

                    this.notificationLabel = data.data.titleLabel;
                    this.notificationsReadLabel = data.data.markSeenButtonLabel;
                    this.notifications = data.data.notifications;

                    this.checkNotificationsAreSeen();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        notificationsOptions() {
            this.toggle = ! this.toggle;
        },

        async deleteNotification(id) {

            return await this.$api.notification.delete(id)
                .then(() => {

                    this.ajaxGetNotifications();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        openNotification(id) {
            this.activeComponentId = id;
        },

        checkNotificationsAreSeen() {

            this.mark = false;

            this.notifications.forEach(notification => {
                if (notification.isSeen === false) {

                    this.mark = true;
                }
            });
        },

        async readAll() {

            return await this.$api.notification.markAll()
                .then(() => {

                    this.ajaxGetNotifications();
                })
                .catch(error => {
                    console.log(error);
                });
        },

        resetNotifications() {
            this.activeComponentId = null;
            this.ajaxGetNotifications();
        }
    },
    created() {
        this.ajaxGetNotifications();
    }
}
</script>
<template>
  <div>
    <EnvelopeHeader :page-title="title" />
    <ForgotPasswordForm
        @get-title="setTitle"
    />
  </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import ForgotPasswordForm from "@/components/form/ForgotPasswordForm";

export default {
  name: 'forgot-password-page',
  data() {
    return {
      title: null
    }
  },
  components: {
    ForgotPasswordForm,
    EnvelopeHeader
  },
  methods: {
    logout() {
      this.$auth.isAuthenticated = 0;
    },
    changeRoute() {
      this.$router.push({ name: 'Dashboard' })
    },
    setTitle(title) {
      this.title = title;
    }
  },
  created() {

  }
}
</script>

<template>

  <Maps @position-change="updateInput"
        :existing-marker="inputValue"
        :addressCoordinates="coordinates"
  />

  <input
      data-is-object="true"

      hidden
      :id="inputId"
      :required="inputRequired"
      ref="input"
  >

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
import Maps from '@/components/form/inputs/partials/Maps'

export default {
  name: 'Input-pinpoint',
  components: {
    Maps
  },
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError',
    'inputHidden',
    'inputAttributes'
  ],
  data() {
    return {
      coordinates: null
    }
  },
  created() {

    if (this.inputAttributes && this.inputAttributes.firstPinpointLocation) {
      this.coordinates = this.inputAttributes.firstPinpointLocation;
    }
  },
  methods: {

    /**
     * If the element should be hidden, hide it
     * Called in mounted
     */
    hidden() {
      this.$refs.input.hidden = true;
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param coordinates
     */
    emitUpdate(coordinates) {

      // construct an object with the params we need to update the widget
      const update = {
        value: coordinates,
        id: this.inputId,
        dataset: null
      }

      this.$emit('update-input', update);
    },

    updateInput(e) {

      // this.$refs.input.value = JSON.stringify(e);
      // this.$refs.input.click();

      this.emitUpdate(e);
    }
  },
  mounted() {
    if (this.inputHidden) {
      this.hidden()
    }
  },
  computed: {
    mapConfig () {
      return {
        center: { lat: 0, lng: 0 }
      }
    },
  }
}
</script>
import TextRoutes from "@/api/routes/TextRoutes";
import FormRoutes from "@/api/routes/FormRoutes";
import AddressRoutes from "@/api/routes/AddressRoutes";
import LocationRoutes from "@/api/routes/LocationRoutes";
import UserRoutes from "@/api/routes/UserRoutes";
import VacancyRoutes from "@/api/routes/VacancyRoutes";
import NotificationRoutes from "@/api/routes/NotificationRoutes";

export const Api = {
    text: TextRoutes,
    form: FormRoutes,
    address: AddressRoutes,
    location: LocationRoutes,
    user: UserRoutes,
    vacancy: VacancyRoutes,
    notification: NotificationRoutes,
};
<template>
  <span
      @click="iconClick"
      ref="icon"
      class="icon"
      :class="[
        { 'icon--animate': animate },
        color,
        size
      ]"
  >
    <span v-if="mark" class="icon__mark"></span>
    <span ref="iconInner" class="icon__inner"></span>
    <i :class="icomoon"></i>
  </span>
</template>

<script>
/**
 * Icon
 *
 * Expects 3 props:
 *
 * - iconSize:
 *    - ''          -> default
 *    - 'small'
 *    - 'big'
 *
 * - iconColor:
 *    - 'dark'      -> default
 *    - 'red'
 *    - 'transparent'
 *
 * - iconClass
 *    - 'account'   -> default
 *    - 'map'
 *    - 'home'
 *    - 'plus'
 *    - 'meatballs'*
 *    - (check icomoon.scss for more)...
 *
 * - mark
 *    - fasle       -> default
 *    - true
 *
 * If props are not passed, we just set the default value
 */
export default {
  name: 'Icon', // eslint-disable-line
  props: [
    'iconSize',
    'iconColor',
    'iconClass',
    'mark'
  ],
  data() {
    return {
      animate: false,
      color: this.iconColor ? 'icon--' + this.iconColor : 'icon--dark',
      size: this.iconSize ? 'icon--' + this.iconSize : '',
      icomoon: this.iconClass ? 'icon-' + this.iconClass : 'icon-account',
    }
  },
  methods: {
    /**
     * Handle the icon animation when clicked
     * @param element
     */
    iconClick(element) {

      this.animate = true;

      this.$refs.iconInner.addEventListener('animationend', () => {
        this.animate = false;
      });
    }
  }
}
</script>
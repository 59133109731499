<template>

  <div class="radio-icons radio-icons-custom">
    <div v-for="radio in inputOptions" :key="radio.value" class="radio-icon-wrapper">
      <input class="radio-icon"
             @change="updateHiddenInput"
             type="radio"

             :ref="radio.label"
             :name="inputId"
             :value="radio.value"
      >
      <div class="radio-icon__svg"
           @click="selectRadio(radio.label)">
        <span v-html="radio.icon"></span>
        <p>{{ radio.label }}</p>
      </div>

      <p class="radio-icon__label--mobile">{{ radio.label }}</p>
    </div>
  </div>

  <input
      @click="emitUpdate"
      type="hidden"
      ref="input"

      :id="inputId"
  >

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
/**
 * This component uses a hidden input as its 'actual' input
 * On submit the value that's given to the hidden input will be the one that's posted
 * Every time a change is made the value of the hidden input is updated to the value of the clicked radio button
 */
export default {
  name: 'Input-radio-icons',
  props: [
    'inputId',
    'inputValue',
    'inputOptions',
    'inputError'
  ],
  methods: {

    /**
     * Just select the right radio button based on the clicked icon
     */
    selectRadio(id) {
      let radio = this.$refs[id][0];
      radio.click();
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e.target.value,
        id: this.inputId,
        dataset: e.target.dataset
      }

      this.$emit('update-input', update);
    },

    /**
     * Updates the value of the hidden input based on the clicked radio
     *
     * @param e
     */
    updateHiddenInput(e) {
      this.$refs.input.value = e.target.value;

      // trigger a click on the hidden input to trigger the emitUpdate function on the hidden input
      // a bit dirty I know, but it works, fight me
      this.$refs.input.click();
    }
  }
}
</script>
<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <FormSlider
              :ajaxPath="'location/form'"
              :ajaxParams="generateUrlParameters()"
              @post-success="redirect"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSlider from "@/components/form/FormSlider";

export default {
  name: 'edit-location-form',
  components: {
    FormSlider
  },
  methods: {

    /**
     * Generate a custom parameter string to add to the requests
     *
     * @returns {string}
     */
    generateUrlParameters() {
      return '?id=' + this.$route.params.hashId;
    },

    redirect() {
      // go to our dashboard
      this.$router.push({ name: 'Dashboard' });
    },
  }
}
</script>
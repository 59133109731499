<template>
    <EnvelopeHeader :page-title="title"/>

    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="privacy-title">{{ title }}</h1>
                <br>
                <div v-html="text"></div>
            </div>
        </div>
    </div>

</template>

<script>

import EnvelopeHeader from "@/components/layout/EnvelopeHeader";

export default {
    name: 'terms-page',
    data() {
        return {
            title: null,
            text: null
        }
    },
    components: {
        EnvelopeHeader,
    },
    created() {
        this.ajaxGetText();
    },
    mounted() {
        this.setPageTitle();
    },
    computed: {
        language() {
            return this.$store.getters['language/getLanguage'];
        }
    },
    watch: {
        language() {
            this.setPageTitle();
            this.ajaxGetText();
        },
    },
    methods: {
        async ajaxGetText() {
            this.loading = true;

            return await this.$api.text.get('text/get/register-form-conditions')
                .then(data => {
                    this.title = data.data.title;
                    this.text = data.data.body;
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },

        setPageTitle() {

            if (this.language === 'nl') {
                document.title = `${termsNL}` + ' - ' + `${appName}`; // eslint-disable-line
            } else if (this.language === 'fr') {
                document.title = `${termsFR}` + ' - ' + `${appName}`; // eslint-disable-line
            } else if (this.language === 'en') {
                document.title = `${termsEN}` + ' - ' + `${appName}`; // eslint-disable-line
            }
        },

        changeRoute() {
            this.$router.push('/dashboard')
        },
        setTitle(title) {
            this.title = title;
        },
    }
}
</script>

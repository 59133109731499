<template>
  <div class="envelope-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="envelope-header__inner">

            <h1 class="page-header" v-if="pageTitle">{{ pageTitle }}</h1>

            <router-link :to="{ name: 'Dashboard', params: { lang: language } }">
              <img class="envelope-header__logo" src="/src/shared/img/VIL-logo-white.svg" alt="VIL Logo">
            </router-link>

            <div class="envelope-header__options">

              <a class="envelope-header__options__home" @click="goHome">
                <Icon
                    :iconColor="'dark'"
                    :iconClass="'home'"
                />
              </a>

              <div v-if="this.$auth.isAuthenticated === '1'" class="envelope-header__options__profile">
                <Icon @click="profileOptions"
                    :iconColor="'dark'"
                    :iconClass="'account'"
                />
                <div v-if="user.data"
                     class="tooltip tooltip--header tooltip--hidden"
                     ref="profileOptions">
                  <div class="tooltip__name">
                      <p>{{ user.data.account.organisationName }}</p>
                      <span v-if="isOrganisationUser()">{{ user.data.firstName }} {{ user.data.surname}}<br></span>
                      <span>Account-ID: {{ user.data.id }}</span>
                  </div>
                  <router-link @click="profileOptions" :to="{ name: 'Profile', params: { lang: language } }">
                    {{ profile }}
                  </router-link>
                  <a v-if="user.data && user.data.role.id === organisationAdminHash" @click="profileOptions" :href="adminUrl" target="_blank">
                    {{ adminLabel }}
                  </a>
                  <router-link @click="profileOptions" :to="{ name: 'Moving', params: { lang: language } }">
                    {{ moving }}
                  </router-link>
                  <router-link @click="profileOptions" :to="{ name: 'HolidayGrid', params: { lang: language } }">
                    {{ holiday }}
                  </router-link>
                  <a @click="logout()">
                    {{ logOut }}
                  </a>
                </div>
              </div>

                <NotificationsComponent />

                <div class="language-switcher" ref="langSwitcher" @click="openLanguageSwitcher">
                <div class="language-switcher__active">
                  {{ language }} <i class="icon-chevron"></i>


                  <div class="language-switcher__switch" @click="openLanguageSwitcher">
                    <a v-if="language !== 'fr'" @click="setLanguage('fr', activeLocale)">FR</a>
                    <a v-if="language !== 'nl'"  @click="setLanguage('nl', activeLocale)">NL</a>
                    <a v-if="language !== 'en'"  @click="setLanguage('en', activeLocale)">EN</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NotificationsComponent from "@/components/layout/partials/Notifications";
export default {
    components: {NotificationsComponent},
    props: ['pageTitle'],
  name: 'EnvelopeHeader',
  data() {
    return {
      profile: `${profile}`, // eslint-disable-line
      moving: `${moving}`, // eslint-disable-line
      holiday: `${holiday}`, // eslint-disable-line
      logOut: `${logOut}`, // eslint-disable-line
      activeLocale: `${activeLocale}`, // eslint-disable-line
      adminUrl: `${adminUrl}`, // eslint-disable-line
      organisationAdminHash: `${organisationAdminHash}`, // eslint-disable-line
      adminLabel: `${adminLabel}`, // eslint-disable-line
      organisationTypeId: `${organisationTypeId}`, // eslint-disable-line
      userRole: `${userRole}` // eslint-disable-line
    }
  },
  computed: {
    language() {
      return this.$store.getters['language/getLanguage'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    }
  },
  methods: {

    async postNewLanguage(locale) {

      return await this.$api.user.locale(locale)
          .then(data => {

            // console.log('Get successful');
            this.$router.push({ name: this.$route.name, params: { lang: locale } })
            this.loading = false;
          })
          .catch(error => {

            console.log(error);
          });
    },

    // check if user is an organisation user which is not an admin
    isOrganisationUser() {
      if (this.user.data.account.type === parseInt(this.organisationTypeId)) {
          return true;
      }

      return false;
    },

    setLanguage(locale, oldLocale) {
      this.$store.dispatch('language/setLanguage', locale);

      if (this.$auth.isAuthenticated === '1') {
        this.postNewLanguage(locale);
        this.openLanguageSwitcher();
      }
      else {
        let url = window.location.href.replace('/' + oldLocale + '/','/' + locale + '/');
        window.location.replace(url);
      }
    },

    openLanguageSwitcher() {
      this.$refs.langSwitcher.classList.toggle('language-switcher--open');
    },

    profileOptions() {
      this.$refs.profileOptions.classList.toggle('tooltip--hidden');
    },

    goHome() {
      this.$emit('go-home');
      this.$router.push({ name: 'Dashboard', params: { lang: this.language } })
    },

    async logout() {

      return await this.$api.user.logout()
          .then(data => {

            this.$auth.isAuthenticated = false;

            console.log('Logged out');
            this.$router.push({ name: 'Home', params: { lang: this.language } })
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>

<style>
</style>

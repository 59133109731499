<template>

  <Toggle :toggleProp="value" @click="updateHiddenInput" />

  <input
      @click="emitUpdate"
      type="checkbox"
      ref="input"
      hidden
      :value="value"
      :id="inputId"
  >

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
export default {
  name: 'Input-text',
  data() {
    return {
      value: this.inputValue
    }
  },
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError',
    'inputHidden'
  ],
  methods: {

    /**
     * If the element should be hidden, hide it
     * Called in mounted
     */
    hidden() {
      this.$refs.input.hidden = true;
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e,
        id: this.inputId,
        dataset: null
      }

      this.$emit('update-input', update);
    },

    /**
     * Updates the value of the hidden input (true/false)
     */
    updateHiddenInput() {

      this.value = ! this.value;

      this.emitUpdate(this.value);
    }
  },
  mounted() {
    if (this.inputHidden) {
      this.hidden()
    }
  }
}
</script>
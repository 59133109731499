<template>
  <div v-if="addressData" class="location-item">

    <div v-if="addressData.isMoving"
      class="location-item__moving">
      {{ movingLabel }}: {{ addressData.dateMoving }}
    </div>

    <section class="location-item__information">
      <div class="location-item__information__title">
        <h2>{{ addressData.title }}</h2>
<!--        <span class="location-item__information__chevron">-->
<!--                  <i class="icon-chevron" @click="foldLocationItem(addressData.id)"></i>-->
<!--                </span>-->
      </div>

      <div style="position: relative">
        <a
            class="location-item__information__edit" @click="locationOptions()">
          <!--        <router-link :to="{ name: 'EditAddress', params: { hashId: addressData.id } }">-->
          <i class="icon-pencil"></i>
          <span class="ml-5">{{ editAddressButtonLabel }}</span>
          <!--        </router-link>-->
        </a>

        <div @click="locationOptions()"
             class="location-item__location__popup location-item__location__popup--hidden"
             ref="edit">

          <a @click="$emit('openAddressModal', addressData.id);">
            {{ editAddressButtonLabel }}
          </a>
          <a v-if="index !== 0" @click="openDeletePopup(addressData.id, 'address')">
            {{ deleteAddressLabel }}
          </a>
        </div>
      </div>

      <div class="location-item__information__location">
        <p>
          {{ addressData.streetName }} {{ addressData.streetNumber }}<br>
          {{ addressData.postalCode }} {{ addressData.city }}
        </p>
        <a v-if="addressData.locations.length" @click="openMaps">
          <Icon
              :iconColor="'red'"
              :iconSize="'small'"
              :iconClass="'map'"
          />
        </a>
      </div>
    </section>

    <section class="location-item__children"
             :class="{ 'location-item__children--animate': folded }">
      <div v-if="addressData.locations.length">
        <Map @highlight-location="highlightLocation"
             :class="{ 'google-maps-open': mapsOpen }"
             :locations="addressData.locations"
             :showDigit="true"
        />
      </div>

<!--      <div >-->

        <div v-for="(location, index) in addressData.locations" :key="location.id"
             class="location-item__location"
             :class="{ 'location-item__location--hidden': !showAll && index > 1 }"
        >
          <div :ref="location.id" class="location-item__location__highlight"></div>

          <Location @toggle-modal="showModal"
                    @delete-location="openDeletePopup(location.id, 'location')"
                    :index="index"
                    :location="location"
                    :user="user"
          />

        </div>

<!--      </div>-->

<!--      <div class="location-item__location location-item__location&#45;&#45;hidden"-->
<!--           @show-all-geolocations.once="showGeoLocation">-->
<!--        <div class="location-item__location__inner">-->
<!--          <p><strong>Front door</strong> (default)</p>-->
<!--          <p>Key: 08292JDD_D222</p>-->
<!--        </div>-->
<!--        <a href="#">-->
<!--          <Icon-->
<!--              :iconColor="'transparent'"-->
<!--              :iconSize="'small'"-->
<!--              :iconClass="'pencil'"-->
<!--          />-->
<!--        </a>-->
<!--      </div>-->

      <div class="location-item__controls">
        <div v-if="addressData.locations.length > 2" class="location-item__load-more">
          <a v-if="!showAll" @click="showAllGeoLocations">
            <strong>{{ showAllButtonLabel }} ({{ locationAmount }})</strong>
          </a>
          <a v-else @click="showAllGeoLocations">
            <strong>{{ showAllButtonLabel }}</strong>
          </a>
        </div>

        <div class="location-item__add">
          <a @click="newLocation(addressData.id, 'location/form')">
          <span>
            <strong>{{ addLocationButtonLabel }}</strong>
          </span>
            <i class="icon-plus"></i>
          </a>
        </div>
      </div>

    </section>

  </div>

  <FormModal @close-modal="closeModal"
             @post-success="postSuccess"
             :visible="modalVisible"
             :formType="'slider'"
             :formPath="modalPath"
             :formParams="activeLocationParams"
  />

  <div class="popup location-delete-popup"
       :class="{ 'popup--hidden': !popupVisible }">
    <div class="container">
      <div class="row justify-content-around">
        <div class="col-lg-6">
          <div class="popup__inner">

            <h3>{{ areYouSure }}</h3>

            <Button @click="closeDeletePopup"
                    :buttonLabel="cancel"
                    :buttonStyle="'transparent'"
                    :buttonSize="'small'"
            />
            <Button v-if="deleteType === 'location'"
                    @click="deleteLocation(deleteId)"
                    :buttonLabel="confirm"
                    :buttonStyle="'red'"
                    :buttonSize="'small'"
            />
            <Button v-if="deleteType === 'address'"
                    @click="deleteAddress(deleteId)"
                    :buttonLabel="confirm"
                    :buttonStyle="'red'"
                    :buttonSize="'small'"
            />

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Map from '@/components/location/partials/Map';
import FormModal from "@/components/form/layout/FormModal";
import Location from "@/components/location/partials/Location";

export default {
  name: 'Location-item',
  data() {
    return {
      folded: false,
      showAll: false,
      locationAmount: 0,
      locations: {},
      modalVisible: false,
      popupVisible: false,
      deleteId: null,
      deleteType: null,
      activeLocationParams: null,
      modalPath: '',
      mapsOpen: false,
      areYouSure: `${areYouSureLabel}`, // eslint-disable-line
      cancel: `${cancelLabel}`, // eslint-disable-line
      confirm: `${deleteLabel}`, // eslint-disable-line
    }
  },
  props: [
    'addressData',
    'showAllButtonLabel',
    'addAddressButtonLabel',
    'addLocationButtonLabel',
    'editAddressButtonLabel',
    'deleteAddressLabel',
    'movingLabel',
    'user',
    'index',
    'goHome'
  ],
  components: {
    Location,
    Map,
    FormModal
  },
  created() {
    this.locationAmount = this.addressData.locations.length;
  },
  watch: {
    goHome: function () {
      this.$emit('reset-go-home');
      this.closeModal();
    },
  },
  methods: {

    /**
     * Open the modal with the new locationForm
     */
    newLocation(id, path) {
      // this.$router.push({ name: 'NewLocation', params: { hashId: this.addressData.id } });

      let params = this.generateUrlParameters(id);
      this.showModal(id, path, params);
    },

    foldLocationItem() {
      this.folded = ! this.folded;
    },

    /**
     * Believe it or not, this function allows us to show all the geolocations for this address
     */
    showAllGeoLocations() {
      this.showAll = !this.showAll;
    },

    locationOptions() {
      // this.$refs[ref][0].classList.toggle('location-item__location__popup--hidden');
      this.$refs.edit.classList.toggle('location-item__location__popup--hidden');
    },

    showModal(id, path, params) {
      this.modalPath = path;
      this.activeLocationParams = params;
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    /**
     * Method to highlight a location in the list when it's corresponding marker is clicked on the map
     */
    highlightLocation(id) {

      this.$refs[id][0].addEventListener('animationend', () => {

        this.$refs[id][0].classList.remove('location-item__location--highlight');
      });

      this.$refs[id][0].classList.add('location-item__location--highlight');
    },

    openDeletePopup(id, type) {
      this.popupVisible = true;
      this.deleteId = id;
      this.deleteType = type;
    },

    closeDeletePopup() {
      this.popupVisible = false;
    },

    openMaps() {
      this.mapsOpen = !this.mapsOpen;
    },

    async deleteLocation(id) {

      return await this.$api.location.delete(id)
          .then(data => {

            this.closeModal();
            this.$emit('reload');
          });
    },

    async deleteAddress(id) {

      return await this.$api.address.delete(id)
          .then(data => {

            this.closeModal();
            this.$emit('reload');
          });
    },

    /**
     * When we change a location on an address, close the formModal
     * Also emit an event to AddressList to reload the addresses
     */
    postSuccess() {
      this.modalVisible = false;
      this.$emit('reload');
    },

    /**
     * Generate a custom parameter string to add to the requests
     *
     * @param id
     * @returns {string}
     */
    generateUrlParameters(id) {
      return '?addressId=' + id;
    },
  }
}
</script>

<template>
  <div ref="messageCard">
<!--    <span class="message-card__close" @click="deleteMessage">-->
<!--      <i class="icon-cancel"></i>-->
<!--    </span>-->
    {{ message }}
  </div>
</template>

<script>

export default {
  name: 'message-card',
  data() {
    return {
      top: null,
      currentIndex: null
    }
  },
  props: [
    'message',
    'index'
  ],
  methods: {
    deleteMessage() {
      this.$store.dispatch('message/deleteMessage');
    }
  },
  mounted() {
    const element = this.$refs.messageCard;

    element.classList.add('message-card--animate');
    element.addEventListener('animationend', () => {

      this.deleteMessage();
    });
  }
}
</script>

<template>
    <div>
        <EnvelopeHeader :page-title="title" />
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <VacationList
                        :user="user"
                        :language="language"
                        :goHome="goHome"

                        @loading-start="loadingStart"
                        @loading-done="loadingDone"
                        @set-title="setTitle"
                        @reset-go-home="resetGoHome"
                    />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import VacationList from "@/components/location/VacationList";

export default {
    name: 'holiday-grid',
    data() {
        return {
            title: null,
            goHome: false
        }
    },
    components: {
        VacationList,
        EnvelopeHeader
    },
    computed: {
        user() {
            return this.$store.getters['user/getUser'];
        },
        language() {
            return this.$store.getters['language/getLanguage'];
        }
    },
    methods: {

        loadingStart() {
            this.loading = true;
        },

        emitGoHome() {
            this.goHome = true;
        },

        resetGoHome() {
            this.goHome = false;
        },

        loadingDone() {
            this.loading = false;
        },

        logout() {
            this.$auth.isAuthenticated = 0;
        },

        changeRoute() {
            this.$router.push('/home')
        },

        setTitle(e) {
            this.title = e;
        }
    }
}
</script>

<template>
  <div class="page-wrap">
    <router-view />
  </div>
  <MessageCards />
<!--  <MobileHomeButton />-->
</template>

<script>
import MessageCards from "@/components/layout/MessageCards";

export default {
  name: 'App',
  components: {
    MessageCards,
  },
  data() {
    return {
      activeLocale: `${activeLocale}`
    }
  },
  created() {
    this.ajaxGetUser();
    // set the language based on the active locale we get from the backend
    this.setLanguage(this.activeLocale);
  },
  computed: {
    language() {
      return this.$store.getters['language/getLanguage'];
    }
  },
  methods: {

    setLanguage(locale) {
      this.$store.dispatch('language/setLanguage', locale);
    },

    // check if we have a user, if we do set the language to the user's language
    async ajaxGetUser() {

      return await this.$api.user.get()
          .then(data => {


            this.$store.dispatch('user/setUser');
            this.setLanguage(data.data.locale);

            // console.log('Get successful');
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
          });
    },
  }
}
</script>
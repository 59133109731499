import { createStore } from 'vuex';
import message from './modules/store';
import user from './modules/store';
import language from './modules/store';
import place from './modules/store';

export default createStore({
    modules: {
        message: message,
        user: user,
        language: language,
        place: place
    }
})
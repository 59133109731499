<template>
  <EnvelopeHeader :page-title="title" />

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="privacy-title">{{ title }}</h1>
        <br>
        <div v-html="text"></div>
      </div>
    </div>
  </div>

</template>

<script>

import EnvelopeHeader from "@/components/layout/EnvelopeHeader";

export default {
  name: 'privacy-page',
  data() {
    return {
      title: null,
      text: null,
      pageTitle: null
    }
  },
  components: {
    EnvelopeHeader,
  },
  created() {
    this.ajaxGetText();
  },
  mounted() {
    this.setPageTitle();
  },
  computed: {
    language() {
      return this.$store.getters['language/getLanguage'];
    }
  },
  watch: {
    language() {
        this.ajaxGetText();
        this.setPageTitle();
    },
  },
  methods: {
    async ajaxGetText() {
      this.loading = true;

      return await this.$api.text.get('text/get/privacy-policy')
          .then(data => {
            this.title = data.data.title;
            this.text = data.data.body;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
          });
    },

    setPageTitle() {

      if (this.language === 'nl') {
        document.title = `${privacyNL}` + ' - ' + `${appName}`; // eslint-disable-line
      }
      else if (this.language === 'fr') {
        document.title = `${privacyFR}` + ' - ' + `${appName}`; // eslint-disable-line
      }
      else if (this.language === 'en') {
        document.title = `${privacyEN}` + ' - ' + `${appName}`; // eslint-disable-line
      }
    },

    changeRoute() {
      this.$router.push('/dashboard')
    },
    setTitle(title) {
      this.title = title;
    },
  }
}
</script>

<template>
  
  <div class="custom-radio">
    <div v-for="option in inputOptions"
         :key="option.value"
         class="radio-input-wrapper">

      <input type="radio" :id="option.value" :name="inputId" :value="option.value">

      <label @click="setActive(option.value)" class="radio-label" :for="option.value"
              :class="{ 'radio-label--active': active === option.value }">
        <span class="default-radio-icon"></span>
        {{ option.address.title }}
      </label>
    </div>
  </div>

  <input
      @click="emitUpdate"
      type="hidden"
      ref="input"

      :id="inputId"
  >

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
/**
 * This component uses a hidden input as its 'actual' input
 * On submit the value that's given to the hidden input will be the one that's posted
 * Every time a change is made the value of the hidden input is updated to the value of the clicked radio button
 */
export default {
  name: 'Input-addresses',
  props: [
    'inputId',
    'inputValue',
    'inputOptions',
    'inputError'
  ],
  data() {
    return  {
      active: null
    }
  },
  methods: {

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param radioValue
     */
    emitUpdate(radioValue) {

      // construct an object with the params we need to update the widget
      const update = {
        value: radioValue,
        id: this.inputId,
        dataset: null
      }

      this.$emit('update-input', update);
    },

    setActive(id) {
      this.active = id;

      this.emitUpdate(id);
    }
  }
}
</script>
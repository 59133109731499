<template>

  <div class="form-review-step">

    <h2 class="form__title--error">{{ reviewData.title }}</h2>
    <br>
    <div class="form-review-step__data">
      <section>
        <Map class="form-review__map" :showDigit="false" :locations="location" />
        <br>
      </section>
      <section v-if="reviewData.code && user.data && user.data.account.type == organisationTypeId">
        <h2>{{ codeLabel }}:</h2>
        <p>{{ reviewData.code }}</p>
      </section>
      <section v-if="reviewData.floorInformation">
        <h2>{{ floorLabel }}:</h2>
        <p>{{ reviewData.floorInformation }}</p>
      </section>
      <section v-if="reviewData.images.length">
        <h2>{{ imagesLabel }}:</h2>
        <div class="row">
          <div v-for="image in reviewData.images" :key="image.id" class="col-sm-4">
            <img :src="image.thumbnail" alt="Preview" class="upload-image">
          </div>
        </div>
      </section>
      <section v-if="reviewData.tools.length">
        <h2>{{ toolsLabel }}:</h2>
        <p v-for="tool in reviewData.tools" :key="tool.id">{{ tool.title }}</p>
      </section>
      <section v-if="reviewData.instructions">
        <h2>{{ instructionsLabel }}:</h2>
        <p>{{ reviewData.instructions }}</p>
      </section>
      <section v-if="reviewData.availabilities">
        <h2>{{ reviewData.availabilityLabel }}:</h2>
        <div v-if="reviewData.alwaysAvailable">
          <h3>{{ reviewData.labelAlwaysAvailable }}</h3>
        </div>
        <div v-else>
          <div v-for="day in reviewData.availabilities" :key="day.id">
            <h3>{{ day.labelWeekday }}</h3>
            <div v-if="day.availabilityFlag === 1">
              <p v-for="time in day.times" :key="time.id">
                <span v-if="day.availabilityFlag === 1">{{ time.timeFrom }} - {{ time.timeTo }}</span>
              </p>
            </div>
            <div v-else>
              <p><span>{{ reviewData.labelNotAvailable }}</span></p>
            </div>
            <br>
          </div>
        </div>
      </section>
      <section v-if="reviewData.alternativeAddresses.length">
        <h2>{{ alternativeAddressesLabel }}</h2>
        <p v-for="address in reviewData.alternativeAddresses" :key="address.id">
          <span><strong>{{ address.title }}</strong></span><br>
          <span>{{ address.address }}</span>
        </p>
      </section>
    </div>

    <section class="form-group__buttons">

      <div>
        <Button @click="this.$emit('reviewCancel');"
            :buttonLabel="buttonLabelPrevious"
            :buttonStyle="'outline'"
            :arrowLeft="true"
            :buttonSize="'small'"
        />
      </div>

      <div class="form__button-wrapper--login form-group__buttons__next">
        <a @click="saveLocation(reviewData.id)" class="form__button">
          <Button
              :buttonLabel="buttonLabelSubmit"
              :buttonStyle="'blue'"
              :buttonSize="'small'"
          />
        </a>
      </div>

    </section>

  </div>
</template>

<script>
import Map from '@/components/location/partials/Map';

export default {
  name: 'form-review-step',
  props: [
    'reviewData',
    'buttonLabelSubmit',
    'buttonLabelPrevious'

  ],
  data() {
    return {
      location: [],
      alternativeAddressesLabel : `${alternativeAddresses}`,
      toolsLabel : `${tools}`,
      availabilitiesLabel : `${availabilities}`,
      imagesLabel : `${images}`,
      codeLabel : `${code}`,
      floorLabel : `${floor}`,
      instructionsLabel : `${instructions}`,
      organisationTypeId: `${organisationTypeId}`
    }
  },
  created() {
    this.location.push(this.reviewData);
  },
  components: {
    Map
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    }
  },
  methods: {

    async saveLocation(id) {

      return await this.$api.location.save(id)
          .then(data => {

            this.$emit('reviewDone');

          })
          .catch(error => {
            console.log(error);
          });
    }
  }
}
</script>
<template>
  <div>
    <EnvelopeHeader :page-title="title" />
    <PasswordRecoveryForm
        @get-title="setTitle"
    />
  </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import PasswordRecoveryForm from "@/components/form/PasswordRecoveryForm";

export default {
  name: 'forgot-password-page',
  data() {
    return {
      title: null
    }
  },
  components: {
    PasswordRecoveryForm,
    EnvelopeHeader
  },
  methods: {

    changeRoute() {
      this.$router.push({ name: 'Dashboard' })
    },

    setTitle(title) {
      this.title = title;
    }
  }
}
</script>

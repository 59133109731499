<template>

    <div v-if="response && !loading && user" class="vacation-list">
        <div class="vacation-list__title">
            <h1>{{ title }}</h1>
            <router-link :to="{ name: 'NewVacationPeriod' }" :title="addButtonLabel">
                <Icon
                    :iconColor="'red'"
                    :iconClass="'plus'"
                />
            </router-link>
        </div>
        <div v-if="vacancies.length">
            <div v-for="vacationPeriod in vacancies" :key="vacationPeriod.id">
                <div class="vacation-item">
                    <div>
                        <p><strong>{{ vacationPeriod.title }}</strong></p>
                        {{ vacationPeriod.dateStart }} - {{ vacationPeriod.dateEnd }}
                    </div>
                    <div>
                        <router-link :to="{ name: 'EditVacationPeriod', params: { lang: language, hashId: vacationPeriod.id } }" :title="editButtonLabel">
                            <Icon
                                :iconClass="'pencil'"
                                :iconSize="'small'"
                            />
                        </router-link>
                        <Icon @click="deleteVacancyPeriod(vacationPeriod.id)" :title="deleteButtonLabel"
                              :iconColor="'transparent'"
                              :iconClass="'cancel'"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p><strong>{{ noVacancyPeriodsLabel }}</strong></p>
        </div>
    </div>

</template>

<script>

export default {
    name: 'vacation-list',
    props: [
        'user',
        'language',
        'goHome'
    ],
    data() {
        return {
            response: {},
            loading: true,
            data: {},
            vacancies: {},
            modalVisible: false,
            addButtonLabel: '',
            editButtonLabel: '',
            deleteButtonLabel: '',
            title: '',
        }
    },
    watch: {
        goHome: function () {
            this.$emit('reset-go-home');
            this.closeModal();
        },
        // if the locale changes, re-fetch the data
        language: function() {
            this.reload();
        }
    },
    methods: {

        /**
         * Get the vacancy periods
         */
        async ajaxGetVacancyPeriods() {
            this.$emit('loadingStart');

            return await this.$api.vacancy.all()
                .then(data => {

                    // set response
                    this.response = data;
                    this.data = data.data;

                    this.vacancies = data.data.vacationPeriods;
                    this.addButtonLabel = data.data.addButtonLabel;
                    this.editButtonLabel = data.data.editButtonLabel;
                    this.deleteButtonLabel = data.data.deleteButtonLabel;
                    this.noVacancyPeriodsLabel = data.data.noVacancyPeriodsLabel;
                    this.title = data.data.title;

                    // console.log('Get successful');
                    this.$emit('loadingDone');
                    this.$emit('setTitle', this.title)
                    this.loading = false;
                });
        },

        async deleteVacancyPeriod(id) {

            this.$emit('loadingStart');

            return await this.$api.vacancy.delete(id)
                .then(data => {

                    this.ajaxGetVacancyPeriods();

                    this.$emit('loadingDone');
                    this.loading = false;
                });
        }
    },
    created() {
        // get the vacancies
        this.ajaxGetVacancyPeriods();
    }
}
</script>

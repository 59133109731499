<template>
  <div>
    <EnvelopeHeader :page-title="title" />

    <MovingForm
        @get-title="setTitle"
    />
  </div>
</template>

<script>

import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import MovingForm from "@/components/form/MovingForm";

export default {
  name: 'register-page',
  data() {
    return {
      title: null
    }
  },
  components: {
    MovingForm,
    EnvelopeHeader,
  },
  methods: {
    changeRoute() {
      this.$router.push('/dashboard')
    },
    setTitle(title) {
      this.title = title;
    }
  }
}
</script>

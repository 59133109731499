<template>
  <div>
    <EnvelopeHeader :page-title="title" />

    <NewAddressForm
        @get-title="setTitle"
    />

  </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import NewAddressForm from "@/components/form/NewAddressForm";

export default {
  name: 'new-address',
  data() {
    return {
      title: null
    }
  },
  components: {
    NewAddressForm,
    EnvelopeHeader
  },
  methods: {

    setTitle(title) {
      this.title = title;
    },
  }
}
</script>

<template>

  <span @click="toggle = !toggle"
     :class="{ 'input-toggle--active': toggle }"
     class="input-toggle">
  </span>

</template>

<script>
/**
 * Toggle
 */
export default {
  name: 'Toggle', // eslint-disable-line
  props: [
    'toggleProp'
  ],
  data() {
    return {
      toggle: 0,
    }
  },
  created() {
    const toggleProp = this.toggleProp;

    if (toggleProp || toggleProp === 1) {
      this.toggle = true;
    }
    else {
      this.toggle = false;
    }
  }
}
</script>
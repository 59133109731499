<template>

    <input
        @blur="emitValidate"
        @input="emitUpdate"
        @click="emitUpdate"

        :id="inputId"
        :required="inputRequired"
        :value="inputValue"
        type="datetime-local"
        ref="input"
    >

    <p class="input-error">{{ inputError }}</p>

</template>

<script>
export default {
    name: 'Input-date-time',
    data() {
        return {
            now: null
        }
    },
    props: [
        'inputId',
        'inputRequired',
        'inputValue',
        'inputError',
        'inputHidden'
    ],
    created() {
        const date = new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        this.now = date.toISOString().slice(0,16);
    },
    methods: {

        /**
         * If the element should be hidden, hide it
         * Called in mounted
         */
        hidden() {
            this.$refs.input.hidden = true;
        },

        /**
         * Emit validate-control event to trigger
         * The parent will listen for this event
         *
         * @param e
         */
        emitValidate(e) {

            // construct an object with the params we need to validate the widget
            const validate = {
                value: e.target.value,
                id: this.inputId,
            }

            this.$emit('validate-control', validate);
        },

        /**
         * Emit update-input event to trigger
         * The parent will listen for this event
         *
         * @param e
         */
        emitUpdate(e) {

            // construct an object with the params we need to update the widget
            const update = {
                value: e.target.value,
                id: this.inputId,
                dataset: e.target.dataset
            }

            this.$emit('update-input', update);
        },
    },
    mounted() {

        if (this.inputValue === null) {
            this.$refs.input.value = this.now;
        }
        // if we have a date string
        else if (typeof this.inputValue === 'string') {
            const date = new Date(this.inputValue);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            this.$refs.input.value = date.toISOString().slice(0,16);
        }

        this.$refs.input.click();
    }
}
</script>
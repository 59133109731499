<template>
  <div class="dashboard-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <EnvelopeHeader :page-title="'Get started'" />

          <div class="get-started">
            <div v-if="text && text['register']" class="get-started__item">

              <h2>{{ text['register'].title }}</h2>
              <p v-html="text['register'].body"></p>
              <Button @click="register"
                      :buttonLabel="text.registerButtonLabel"
                      :buttonStyle="'blue'"
                      :buttonSize="'small'"
              />

            </div>

            <div v-if="text && text['login']" class="get-started__item">

              <h2>{{ text['login'].title }}</h2>
              <p v-html="text['register'].body"></p>
              <Button @click="login"
                      :buttonLabel="text.loginButtonLabel"
                      :buttonStyle="'blue'"
                      :buttonSize="'small'"
              />

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";

export default {
  name: 'Home-page',
  components: {
    EnvelopeHeader,
  },
  data() {
    return {
      text: null,
      loading: true
    }
  },
  methods: {
    async ajaxGetText() {
      return await this.$api.text.get('user')
          .then(data => {

            this.text = data.data;
            this.loading = false;

            // console.log(data)
          })
          .catch(error => {
            console.log(error);
          })
    },

    register() {
      this.$router.push({ name: 'Register' });
    },

    login() {
      this.$router.push({ name: 'Login' });
    }
  },
  created() {
    this.ajaxGetText()
  }
}
</script>

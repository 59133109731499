<template>

    <label @click="setActive" class="radio-label radio-label--checkbox" :for="location.id"
           :class="{ 'radio-label--active': active }">
        <span class="default-radio-icon"></span>
        {{ location.title }}
    </label><br>

    <input
        :id="location.id"
        :value="location.checked"
        type="text"
        ref="input"
        hidden
    >

</template>

<script>
export default {
    name: 'Vacation-location',
    data() {
        return {
            active: true
        }
    },
    props: [
        'location'
    ],
    created() {
         if (this.location.checked) {
             this.$emit('addLocation', this.location.id);
         }
         else {
             this.active = false;
         }
    },
    methods: {

        setActive() {
            this.active = !this.active;

            if ( ! this.active) {
                this.$emit('removeLocation', this.location.id);
            }
            else {
                this.$emit('addLocation', this.location.id);
            }

            this.$refs.input.value = this.$refs.input.value === 'false';
        },
    }
}
</script>
<template>

  <div class="message-cards-wrapper">
    <MessageCard v-for="message in messages" :key="message" class="message-card"
        :message="message" :index="messages.indexOf(message)" />
  </div>

<!--  <span @click="addMessage">add</span>-->

</template>

<script>

import MessageCard from "@/components/layout/partials/MessageCard";
export default {
  name: 'cards-component',
  components: {MessageCard},
  computed: {
    messages() {
      return this.$store.getters['message/getMessages'];
    }
  },
  methods: {
    addMessage() {
      const message = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
      this.$store.dispatch('message/setMessage', message);
    },
  }
}
</script>

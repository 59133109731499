<template>
    <div class="form-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <Form
                        :ajaxPath="'vacation-period/form'"
                        :ajaxParams="generateUrlParameters()"
                        @post-success="redirect"
                    />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
    name: 'edit-vacation-period-form',
    components: {
        Form
    },
    methods: {

        /**
         * Generate a custom parameter string to add to the requests
         *
         * @returns {string}
         */
        generateUrlParameters() {
            return '?id=' + this.$route.params.hashId;
        },

        redirect() {
            // go to our dashboard
            this.$router.push({ name: 'HolidayGrid' });
        },
    }
}
</script>
<template>

  <textarea
      @blur="emitValidate"
      @input="emitUpdate"

      :id="inputId"
      :required="inputRequired"
      :value="inputValue"
      ref="input"
  ></textarea>

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
export default {
  name: 'Input-text',
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError',
    'inputHidden'
  ],
  methods: {

    /**
     * If the element should be hidden, hide it
     * Called in mounted
     */
    hidden() {
      this.$refs.input.hidden = true;
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e.target.value,
        id: this.inputId,
        dataset: e.target.dataset
      }

      this.$emit('update-input', update);
    }
  },
  mounted() {
    if (this.inputHidden) {
      this.hidden()
    }
  }
}
</script>
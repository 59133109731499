<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <FormSlider
              :ajaxPath="'address/move'"
              @post-success="redirect"
              @get-title="emitTitle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSlider from "@/components/form/FormSlider";

export default {
  name: 'moving-form',
  components: {
    FormSlider
  },
  data() {
    return {
      userId: ''
    }
  },
  methods: {

    redirect() {
      this.$router.push({ name: 'Dashboard' });
    },

    // get the userId from response todos
    handleRedirectParams(todos) {

      todos.forEach((todo) => {

        if (todo['eventName'] === 'saveDataObject' && todo['eventData'].dataObjectId) {
          this.userId = todo['eventData'].dataObjectId;
        }
      });

      return null;
    },

    emitTitle(title) {

      this.$emit('get-title', title);
    }
  }
}
</script>
import GoogleMapsApiLoader from "google-maps-api-loader";

let googleMapsApi = null;

export default {

    async getGoogleMapsApi() {

        if (googleMapsApi === null) {

            googleMapsApi = await GoogleMapsApiLoader({
                apiKey: `${googleMapsKey}`,
                language: '',
                libraries: ['geometry', 'places']
            })
        }

        return googleMapsApi;
    }
}
<template>

  <div :id="inputId" class="address-input">

    <div class="form-grid--8">
      <label for="streetName">{{ inputAttributes.lables.streetName }}</label><br>
      <input
             id="streetName"
             :value="inputValue.streetName"
             placeholder=""
             type="text"
             ref="streetName"
             @input="emitUpdate"
             @blur="emitUpdate"
      >
    </div>


    <div class="form-grid--2">
      <label for="streetNumber">{{ inputAttributes.lables.streetNumber }}</label><br>
      <input
             id="streetNumber"
             :value="inputValue.streetNumber"
             type="text"
             ref="streetNumber"
             @input="emitUpdate"
             @blur="emitUpdate"
      >
    </div>

    <div class="form-grid--2">
      <label for="boxNumber">{{ inputAttributes.lables.boxNumber }}</label><br>
      <input
             id="boxNumber"
             :value="inputValue.boxNumber"
             :placeholder="inputAttributes.placeholders.boxNumber"
             type="text"
             ref="boxNumber"
             @input="emitUpdate"
             @blur="emitUpdate"
      >
    </div>

    <div class="form-grid--4">
      <label for="postalCode">{{ inputAttributes.lables.postalCode }}</label><br>
      <input
             id="postalCode"
             :value="inputValue.postalCode"
             type="text"
             ref="postalCode"
             @input="emitUpdate"
             @blur="emitUpdate"
      >
    </div>

    <div class="form-grid--8">
      <label for="city">{{ inputAttributes.lables.city }}</label><br>
      <input
             id="city"
             :value="inputValue.city"
             placeholder=""
             type="text"
             ref="city"
             @input="emitUpdate"
             @blur="emitUpdate"
      >
    </div>

  </div>

  <p class="input-error">{{ inputError }}</p>

</template>

<script>

export default {
  name: 'Input-address',
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError',
    'inputHidden',
    'inputAttributes'
  ],
  data() {
    return {
      value: null,
      placeholder: '',
      google: null,
      placeWidgets: [
        'streetNumber',
        'streetName',
        'city',
        'streetNumber'
      ]
    }
  },
  created() {
    this.value = this.inputValue;
  },
  methods: {

    /**
     * If the element should be hidden, hide it
     * Called in mounted
     */
    hidden() {
      this.$refs.input.hidden = true;
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     */
    emitValidate() {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: this.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {
      let input = e;

      // if the event has a target property, set the input variable to the target
      if (e.target) {
        input = e.target;
      }

      this.value[input.id] = input.value;

      // construct an object with the params we need to update the widget
      const update = {
        value: this.value,
        id: this.inputId,
        dataset: input.dataset
      }

      if (this.placeWidgets.includes(input.id)) {

        this.addPlaceDataToLocalStorage(update.value);
      }

      this.$emit('update-input', update);
    },

    addPlaceDataToLocalStorage(value) {

      const payload = {
        value: value,
        id: this.inputId
      }

      this.$store.dispatch('place/setPlace', payload);
    },

    autoCompleteCities() {
      const input = document.querySelector('#city');
      const options = {

        componentRestrictions: { country: "be" },
        fields: ['address_components'],
        types: ['(cities)'],
      };

      const autoComplete = new this.google.maps.places.Autocomplete(input, options);

      autoComplete.addListener("place_changed", () => {
        const place = autoComplete.getPlace();

        place.address_components.forEach(item => {

          // now based on the result data fill in the fields
          if (item.types.includes('locality')) {

            this.value.city = item['long_name'];
            input.blur();
          }
        });
      });
    },

    autoCompleteStreets() {
      const input = document.querySelector('#streetName');

      const options = {
        componentRestrictions: { country: "be" },
        fields: ['address_components'],
        types: ['address'],
      };

      // make an autoComplete object based on the settings above
      const autoComplete = new this.google.maps.places.Autocomplete(input, options);

      // now, when the street changed also change the other fields
      autoComplete.addListener("place_changed", () => {

        const place = autoComplete.getPlace();

          place.address_components.forEach(item => {

            // now based on the result data fill in the fields
            if (item.types.includes('route')) {

              this.value.streetName = item['long_name'];
              input.blur();
            }
            else if (item.types.includes('postal_code')) {

              const postalCode = document.querySelector('#postalCode');
              this.value.postalCode = item['long_name'];
              postalCode.value = item['long_name'];
              this.$refs.postalCode.blur();
            }
            else if (item.types.includes('locality')) {

              const city = document.querySelector('#city');
              this.value.city = item['long_name'];
              city.value = item['long_name'];
              this.$refs.postalCode.blur();
            }
            else if (item.types.includes('street_number')) {
              const streetNumber = document.querySelector('#streetNumber');
              this.value.streetNumber = item['long_name'];
              streetNumber.value = item['long_name'];
              this.$refs.postalCode.blur();
            }
          });
      });
    }
  },
  async mounted() {
    this.google = await this.$googleMaps;
    this.google.maps.event.addDomListener(document.querySelector('#streetName'), 'keypress', this.autoCompleteStreets());
    this.google.maps.event.addDomListener(document.querySelector('#city'), 'keypress', this.autoCompleteCities());
  },
}
</script>
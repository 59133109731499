<template>
    <div>
        <EnvelopeHeader :page-title="title" />

        <VacationEntryForm />

    </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import VacationEntryForm from "@/components/form/VacationEntryForm";

export default {
    name: 'new-vacation-period-page',
    data() {
        return {
            title: null
        }
    },
    components: {
        VacationEntryForm,
        EnvelopeHeader
    },
    methods: {

        setTitle(title) {
            this.title = title;
        }
    }
}
</script>

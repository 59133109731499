import { createWebHistory, createRouter } from "vue-router";
import { Auth } from '@/auth/auth'
import Home from '@/pages/Home'
import Dashboard from '@/pages/Dashboard'
import Register from "@/pages/Register";
import Login from "@/pages/Login";
import NewLocation from "@/pages/NewLocation";
import ActivateForm from "@/components/form/ActivateForm";
import GetStarted from "@/pages/GetStarted";
import ForgotPassword from "@/pages/ForgotPassword";
import PasswordRecovery from "@/pages/PasswordRecovery";
import Profile from "@/pages/Profile";
import EditAddress from "@/pages/EditAddress";
import EditLocation from "@/pages/EditLocation";
import NewAddress from "@/pages/NewAddress";
import Moving from "@/pages/Moving";
import Privacy from "@/pages/Privacy";
import Terms from "@/pages/Terms";
import HolidayGrid from "@/pages/HolidayGrid";
import NewVacationPeriod from "@/pages/NewVacationPeriod";
import EditVacationPeriod from "@/pages/EditVacationPeriod";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/home',
        name: 'Home',
        component: Home,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/register',
        name: 'Register',
        component: Register,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/get-started',
        name: 'GetStarted',
        component: GetStarted,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/login',
        name: 'Login',
        component: Login,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/password-recovery/:token?',
        name: 'PasswordRecovery',
        component: PasswordRecovery,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/activate/:userId?/:activationCode?',
        name: 'Activate',
        component: ActivateForm,
        meta: { noAuth: 1 }
    },
    {
        path: '/:lang?/privacy-policy',
        name: 'Privacy',
        meta: { customTitle: 1 },
        component: Privacy,
    },
    {
        path: '/:lang?/terms-and-conditions',
        alias: ['/:lang?/algemene-voorwaarden', '/:lang?/conditions-generales'],
        name: 'Terms',
        meta: { customTitle: 1 },
        component: Terms,
    },
    {
        path: '/:lang?/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/edit-address/:hashId?',
        name: 'EditAddress',
        component: EditAddress,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/new-location/:hashId?',
        name: 'NewLocation',
        component: NewLocation,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/new-address',
        name: 'NewAddress',
        component: NewAddress,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/moving/:hashId?',
        name: 'Moving',
        component: Moving,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/edit-location/:hashId?',
        name: 'EditLocation',
        component: EditLocation,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/holiday',
        name: 'HolidayGrid',
        component: HolidayGrid,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/holiday/add/:hashId?',
        name: 'EditVacationPeriod',
        component: EditVacationPeriod,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:lang?/dashboard/holiday/add',
        name: 'NewVacationPeriod',
        component: NewVacationPeriod,
        meta: { requiresAuth: 1 }
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/home'
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: '/nl',
    routes,
});

router.beforeEach((to, from, next) =>
{

    // if the route has a custom title don't default to the appName
    if (to.matched.some(record => ! record.meta.customTitle)) {
        document.title = `${appName}`; // eslint-disable-line
    }

    // if we need to be authenticated
    if (to.matched.some(record => record.meta.requiresAuth)) {

        // this route requires auth, check if logged in
        if (Auth.isAuthenticated === '1')  {
            next();
        }
        // if not, redirect to login page.
        else {
            console.log('cannot route to this route, need to be authenticated');
            next({
                path: '/home',
                query: { redirect: to.fullPath }
            });
        }
    }

    // if we should not be authenticated
    else if (to.matched.some(record => record.meta.noAuth)) {

        // this route requires auth, check if logged in
        if (Auth.isAuthenticated !== '1')  {
            next();
        }
        // if not, redirect to login page.
        else {
            console.log('cannot route to this route, need to be authenticated');
            next({
                path: '/dashboard',
                query: { redirect: to.fullPath }
            });
        }
    }
    else {
        next();
    }
});

export default router;

<template>

  <div class="availablility">
    <div class="availablility-wrapper availablility-wrapper__day">
      <span>{{ attributes.weekdays[availability.weekday] }}</span>
      <Toggle @click="toggleAvailability" ref="toggle" :toggleProp="this.day.availabilityFlag" />
    </div>

    <AvailabilityHours :class="{ 'no-display': day.availabilityFlag === 0 }"
                       @hours-changed="updateHours"
                       :times="availability.times"
                       :attributes="attributes"
    />
  </div>

</template>
<script>

import AvailabilityHours from "@/components/form/inputs/custom/availabilities/AvailabilityHours";
export default {
  name: 'availability-day',
  components: {AvailabilityHours},
  props: [
    'availability',
    'attributes'
  ],
  data() {
    return {
      day: this.availability,
      hourObject: {
        identifier: "",
        timeFrom: "",
        timeTo: ""
      }
    }
  },
  mounted() {

    if (this.day.availabilityFlag === true || this.day.availabilityFlag === 1) {
      this.day.availabilityFlag = 1;
    }
    else {
      this.day.availabilityFlag = 0;
    }
  },
  methods: {

    updateHours(e) {
      this.day.times = e;

      this.$emit('dayChanged', this.day);
    },

    toggleAvailability() {

      if (this.day.availabilityFlag === 1) {
        this.day.availabilityFlag = 0;
      }
      else {
        this.day.availabilityFlag = 1;
      }

      this.$emit('dayChanged', this.day);
    }
  }
}
</script>
<template>

  <div class="google-maps-wrapper google-maps-form">
    <Loader v-if="loading" />
    <div class="google-map" :class="{ 'blur': loading }" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
          :google="google"
          :map="map"
      />
    </template>
  </div>

</template>

<script>
import Loader from "@/components/layout/Loader";

export default {
  name: 'maps-component',
  props: [
    'existingMarker',
    'addressCoordinates'
  ],
  components: {
    Loader
  },
  data() {
    return {
      location: {},
      google: null,
      map: null,
      apiKey: `${googleMapsKey}`, // eslint-disable-line
      marker: null,
      loading: false,
      circleRadius: null,
      initialCoordinates: null,
      organisationTypeId: `${organisationTypeId}` // eslint-disable-line
    }
  },
  async mounted() {
    this.google = await this.$googleMaps;
    this.initMap();
  },
  computed: {
    place() {
      return this.$store.getters['place/getPlace'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    }
  },
  methods: {

    initMap: function () {

      this.map = new this.google.maps.Map(this.$refs.googleMap, {
        center: {lat: 51.260197, lng: 4.402771},
        zoom: 10,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      });


      this.infoWindow = new this.google.maps.InfoWindow();

      const locationButton = document.createElement("span");

      this.$refs.googleMap.appendChild(locationButton);

      if (this.addressCoordinates) {

        this.handleCircleRadius();

        const map = this.map;

        const coordinates = {
          lat: parseFloat(this.addressCoordinates.latitude),
          lng: parseFloat(this.addressCoordinates.longitude)
        }

        this.map.setCenter(coordinates);
        this.map.setZoom(17);


        // set radius based on account type
        let radius = 100;

        if (this.user.data.account.type === organisationTypeId) {
          radius = 1000;
        }

        this.circleRadius = new this.google.maps.Circle({
          strokeColor: "#007AC9",
          clickable: false,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#D2D3DB",
          fillOpacity: 0.35,
          map,
          center: coordinates,
          radius: radius,
        });
      }

      locationButton.textContent = "Zoek huidige locatie";
      locationButton.classList.add("button", "button--blue", "button--small");

      this.map.controls[this.google.maps.ControlPosition.TOP_RIGHT].push(locationButton);
      this.generateGeolocationMarker(locationButton);
      this.generateMarkerOnClick();

      this.handleStorePlace();

      // if we received an existing marker location (in the edit form for example)
      // we generate a marker based on the properties we received
      if (this.existingMarker) {

        const position = {
          lat: parseFloat(this.existingMarker.latitude),
          lng: parseFloat(this.existingMarker.longitude),
        };

        this.location = {
          latitude: parseFloat(this.existingMarker.latitude),
          longitude: parseFloat(this.existingMarker.longitude),
        }

        this.generateMarker(position);
      }

      // add a listener to the marker, if we drag the marker we update the location
      this.google.maps.event.addListener(this.marker, 'dragend', () => {

        this.markerPositionChange();
      });
    },

    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
          browserHasGeolocation
              ? "Gelieve akkoord te gaan met het detecteren van je locatie."
              : "De browser ondersteunt geen geolocatie."
      );
      infoWindow.open(this.map);
      this.loading = false;
    },

    handleCircleRadius() {
      const self = this;
      this.google.maps.Circle.prototype.contains = function(latLng) {
        return this.getBounds().contains(latLng) && self.google.maps.geometry.spherical.computeDistanceBetween(this.getCenter(), latLng) <= this.getRadius();
      }
    },

    generateGeolocationMarker(locationButton) {

      locationButton.addEventListener("click", () => {

        this.loading = true;

        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                const pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };

                this.generateMarker(pos);
              },
              () => {
                this.handleLocationError(true, this.infoWindow, this.map.getCenter());
              },
              { enableHighAccuracy: true }
          );
        } else {
          // Browser doesn't support Geolocation
          this.handleLocationError(false, this.infoWindow, this.map.getCenter());
        }
      });
    },

    generateMarkerOnClick() {

      this.google.maps.event.addListener(this.map, 'click', (e) => {
        const latLng = e.latLng;

        const position = {
          lat: latLng.lat(),
          lng: latLng.lng(),
        };

        // if there's circle we cannot generate markers outside of it
        if (this.circleRadius && ! this.circleRadius.contains(position)) {
          return;
        }

        this.generateMarker(position);
      });
    },

    generateMarker(position) {

      if (this.marker) {
        this.marker.setPosition(position);
      }
      else {

        this.marker = new google.maps.Marker({
          position: position,
          title: 'Marker',
          map: this.map,
          draggable: true,
          icon: `${baseHref}` + '/src/shared/img/marker.png' // eslint-disable-line
        });

        // add a dragend listener to the new marker
        this.google.maps.event.addListener(this.marker, 'dragend', () => {
          this.markerPositionChange();
        });
      }

      this.map.setCenter(position);
      this.map.setZoom(18);

      this.markerPositionChange();
      this.loading = false;
    },

    markerPositionChange() {

      const newLocation = {
        lat: this.marker.position.lat(),
        lng: this.marker.position.lng(),
      };

      if (this.circleRadius && ! this.circleRadius.contains(newLocation)) {

        this.marker.setPosition(new this.google.maps.LatLng({
              lat: this.location.latitude,
              lng: this.location.longitude
        }));
        return;
      }

      this.location = {
        latitude: this.marker.position.lat(),
        longitude: this.marker.position.lng(),
      };

      this.$emit('positionChange', this.location);

    },

    async handleStorePlace() {

      if (this.place) {

        let queryString = '';

        if (this.place.value.streetName !== null) {
          queryString = this.place.value.streetName;
        }
        if (this.place.value.streetNumber !== null) {
          queryString = queryString + '+' + this.place.value.streetNumber;
        }
        if (this.place.value.city !== null) {
          queryString = queryString + '+' + this.place.value.city;
        }

        if (queryString !== '') {

          this.loading = true;

          const result = await this.getCoordinates(queryString)
            .then(data => {

              this.$store.dispatch('place/deletePlace');

              const position = {
                lat: data.data.latitude,
                lng: data.data.longitude,
              };

              this.generateMarker(position);
            })
            .catch(error => {
              console.log(error);
            });

          this.loading = false;
        }
      }
    },

    async getCoordinates(queryString) {

      return await this.$api.form.coordinates(queryString);
    }
  }
}
</script>
<template>

  <div class="input-edit">
    <span class="input-edit__edit" ref="inputEdit" @click="edit">
      <i class="icon-eye"></i>
    </span>
    <span class="input-edit__revert input-edit__revert--hide" ref="inputRevert" @click="revert">
      <i class="icon-cancel"></i>
    </span>
  </div>

  <input
      @blur="emitValidate"
      @input="emitUpdate"
      :id="inputId"
      :required="inputRequired"
      :value="inputValue"
      type="password"
      ref="input"
  >

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
export default {
  name: 'Input-password',
  data() {
    return {
      inputEdit: false
    }
  },
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError'
  ],
  methods: {

    edit() {
      // don't do stuff when input is already changed
      if (this.inputEdit === true) return;

      this.inputEdit = !this.inputEdit;

      // change input type
      this.$refs.input.type = 'text';
      // hide edit button
      this.$refs.inputEdit.classList.add('input-edit__edit--hide');
      // show revert button
      this.$refs.inputRevert.classList.remove('input-edit__revert--hide');
    },

    revert() {
      // don't do stuff when input is already changed
      if (this.inputEdit === false) return;

      this.inputEdit = false;

      // change input type
      this.$refs.input.type = 'password';
      // hide edit button
      this.$refs.inputEdit.classList.remove('input-edit__edit--hide');
      // show revert button
      this.$refs.inputRevert.classList.add('input-edit__revert--hide');
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e.target.value,
        id: this.inputId,
        dataset: e.target.dataset
      }

      this.$emit('update-input', update);
    },
  }
}
</script>



<template>

  <MapsAutocomplete v-if="this.inputId === 'city'"
      :inputId="inputId" />

  <input
      @blur="emitValidate"
      @input="emitUpdate"

      :id="inputId"
      :required="inputRequired"
      :value="inputValue"
      :placeholder="placeholder"
      type="text"
      ref="input"
  >
  
  <p class="input-error">{{ inputError }}</p>

</template>

<script>

import MapsAutocomplete from "@/components/form/inputs/partials/MapsAutocomplete";
export default {
  name: 'Input-text',
  components: {MapsAutocomplete},
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError',
    'inputHidden',
    'inputAttributes'
  ],
  data() {
    return {
      currentValue: null,
      placeholder: '',
      placeWidgets: [
          'streetNumber',
          'streetName',
          'city'
      ]
    }
  },
  created() {

    if (this.inputAttributes && this.inputAttributes.placeholder) {
      this.placeholder = this.inputAttributes.placeholder;
    }
  },
  methods: {

    /**
     * If the element should be hidden, hide it
     * Called in mounted
     */
    hidden() {
      this.$refs.input.hidden = true;
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e.target.value,
        id: this.inputId,
        dataset: e.target.dataset
      }

      if (this.placeWidgets.includes(this.inputId)) {

        this.addPlaceDataToLocalStorage(update.value);
      }

      // if (this.inputId === 'city') {
      //
      //   this.autofillPlaces();
      // }

      this.$emit('update-input', update);
    },

    addPlaceDataToLocalStorage(value) {

      const payload = {
        value: value,
        id: this.inputId
      }

      this.$store.dispatch('place/setPlace', payload);
    },

    autofillPlaces() {

      const config = {
        method: 'get',
        url: 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=hoogstraten&key=' + `${googleMapsKey}`,
        headers: { }
      };

      fetch(config.url, {
        method: 'GET',
      })
        .then(response => {
          console.log(JSON.stringify(response.data));
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.inputHidden) {
      this.hidden()
    }
  }
}
</script>
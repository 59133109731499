import * as Vue from "vue/dist/vue.esm-bundler.js";

import InputText from '@/components/form/inputs/custom/InputText'
import InputPassword from '@/components/form/inputs/custom/InputPassword'
import InputRadioIcons from '@/components/form/inputs/custom/InputRadioIcons'
import InputCheckbox from '@/components/form/inputs/custom/InputCheckbox'
import InputActivationCode from "@/components/form/inputs/custom/InputActivationCode";
import InputTextLocked from "@/components/form/inputs/custom/InputTextLocked";
import InputSelectLocked from "@/components/form/inputs/custom/InputSelectLocked";
import InputTextArea from "@/components/form/inputs/custom/InputTextArea";
import InputToggle from "@/components/form/inputs/custom/InputToggle";
import InputAvailability from "@/components/form/inputs/custom/InputAvailability";
import InputUpload from "@/components/form/inputs/custom/InputUpload";
import InputPinpoint from "@/components/form/inputs/custom/InputPinpoint";
import InputDate from "@/components/form/inputs/custom/InputDate";
import InputAddresses from "@/components/form/inputs/custom/InputAddresses";
import InputCheckboxes from "@/components/form/inputs/custom/InputCheckboxes";
import InputAddress from "@/components/form/inputs/custom/InputAddress";
import InputDateTime from "@/components/form/inputs/custom/InputDateTime";
import InputLocationCheckboxes from "@/components/form/inputs/custom/InputLocationCheckboxes";

/**
 * Custom form inputs
 *
 * Install the custom form inputs
 */
export default {
    install: (app) => { // eslint-disable-line

        app.component('ak-input-text', InputText);
        Vue.compile('<ak-input-text />');

        app.component('ak-input-password', InputPassword);
        Vue.compile('<ak-input-password />');

        app.component('ak-radio-icons', InputRadioIcons);
        Vue.compile('<ak-radio-icons />');

        app.component('ak-checkbox', InputCheckbox);
        Vue.compile('<ak-checkbox />');

        app.component('ak-input-activation-code', InputActivationCode);
        Vue.compile('<ak-checkbox />');

        app.component('ak-input-text-locked', InputTextLocked);
        Vue.compile('<ak-input-text-locked />');

        app.component('ak-select-locked', InputSelectLocked);
        Vue.compile('<ak-select-locked />');

        app.component('ak-textarea', InputTextArea);
        Vue.compile('<ak-textarea />');

        app.component('ak-form-toggle', InputToggle);
        Vue.compile('<ak-form-toggle />');

        app.component('ak-availability', InputAvailability);
        Vue.compile('<ak-availability />');

        app.component('ak-upload-media', InputUpload);
        Vue.compile('<ak-upload-media />');

        app.component('ak-pinpoint', InputPinpoint);
        Vue.compile('<ak-pinpoint />');

        app.component('ak-date-picker', InputDate);
        Vue.compile('<ak-date-picker />');

        app.component('ak-radio-address', InputAddresses);
        Vue.compile('ak-radio-address');

        app.component('ak-checkboxes', InputCheckboxes);
        Vue.compile('ak-checkboxes');

        app.component('ak-address', InputAddress);
        Vue.compile('ak-address');

        app.component('ak-date-time-picker', InputDateTime);
        Vue.compile('ak-date-time-picker');

        app.component('ak-location-checkboxes', InputLocationCheckboxes);
        Vue.compile('ak-location-checkboxes');
    },
};
<template>

  <div class="google-maps-address">

    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
          :google="google"
          :map="map"
      />
    </template>
  </div>

</template>

<script>

export default {
  name: 'maps-component',
  props: [
    'locations',
    'showDigit'
  ],
  data() {
    return {
      location: {},
      google: null,
      map: null,
      apiKey: `${googleMapsKey}`, // eslint-disable-line
      markers: []
    }
  },
  async mounted() {
    this.google = await this.$googleMaps;
    this.initMap()
  },
  methods: {

    initMap() {

      // initialize the map, use the coordinates of the first location as the center
      this.map = new this.google.maps.Map(this.$refs.googleMap, {
        center: { lat: parseFloat(this.locations[0].latitude), lng: parseFloat(this.locations[0].longitude) },
        zoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      });
      this.infoWindow = new this.google.maps.InfoWindow();

      // if we received an existing marker location (in the edit form for example)
      // we generate a marker based on the properties we received
      if (this.locations) {

        this.locations.forEach((location, index) => {

          // add a timeout to offset the animation (looks cooler)
          let timeout = setTimeout(() => {

            this.generateMarker(location, index, location.title);

            // because timeout doesn't just work inside of a foreach
            // we have to multiply our timout with the current iteration index
            // first iteration = 0 * 250 = immediate
            // second iteration = 1 * 250 = after 250 milliseconds
            // etc...
          }, index * 250);
        });

        if (this.locations.length > 1) {

          const bounds = new this.google.maps.LatLngBounds();

          this.locations.forEach((item) => {
            bounds.extend({ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) });
          });

          this.map.setCenter(bounds.getCenter());
          this.map.fitBounds(bounds);
        }
      }
    },

    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
          browserHasGeolocation
              ? "Gelieve akkoord te gaan met het detecteren van je locatie."
              : "De browser ondersteunt geen geolocatie."
      );
      infoWindow.open(this.map);
    },

    generateMarker(location, index, title = 'Geolocation') {

      // set the position of our marker based on the coordinates we received
      const position = {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      };

      // init the marker
      let marker = new google.maps.Marker({
        position: position,
        title: title,
        map: this.map,
        label: {
          text: (index + 1).toString(),
          color: "#FFFFFF",
          fontSize: "24px",
          fontWeight: "bold"
        },
        draggable: false,
        animation: this.google.maps.Animation.DROP,
        icon: `${baseHref}` + '/src/shared/img/marker.png' // eslint-disable-line
      });

      // if we don't want to show the label on the marker, remove it
      if (!this.showDigit) {
        marker.label = null;
      }

      // add a listener to the marker, if we click it open the corresponding location form
      this.google.maps.event.addListener(marker, 'click', () => {
        this.$emit('highlightLocation', location.id);
        // this.$router.push({ name: 'EditLocation', params: { hashId: location.id } })
      });

      this.markers.push(marker);
    },
  }
}
</script>
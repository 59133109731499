<template>
  <span
      class="button"
      :class="[
          style,
          size
      ]"
  >
    <i v-if="arrowLeft" class="icon-arrow-previous mr-10"></i>
    {{ buttonLabel }}
    <i v-if="arrowRight" class="icon-arrow-next ml-10"></i>
    <i v-if="customIcon" :class="'icon-' + customIcon" class="ml-10"></i>
  </span>
</template>

<script>
/**
 * Button
 *
 * Expects 5 props:
 *
 * - buttonLabel:
 *    - The label to use in the button
 *
 * - buttonStyle:
 *    - ''          -> default
 *    - 'outline'
 *    - 'no-outline'
 *    - 'outline-white'
 *    - 'red'
 *    - 'blue'
 *
 * - buttonSize
 *    - ''          -> default
 *    - 'small'
 *
 * - arrowRight & arrowLeft
 *    - false       -> default
 *    - true
 *
 * If props are not passed, we just set the default value
 */
export default {
  name: 'Button', // eslint-disable-line
  props: [
    'buttonLabel',
    'buttonStyle',
    'buttonSize',
    'customIcon',
    'arrowRight', // if this prop returns true, print arrow right icon
    'arrowLeft' // if this prop returns true, print arrow left icon
  ],
  data() {
    return {
      style: this.buttonStyle ? 'button--' + this.buttonStyle : '',
      size: this.buttonSize ? 'button--' + this.buttonSize : ''
    }
  }
}
</script>
<template>

  <div v-if="text && textGroups" class="about-slider">

    <div class="about-slider__content">
      <div v-for="item in textGroups" :key="item.title"
           class="about-slider__group"
           :class="{ 'about-slider__group--hidden': active.title !== item.title }">

        <h2>{{ item.title }}</h2>
        <span v-html="item.body"></span>


        <div class="about-slider__get-started">
          <Button v-if="!active.next"
                  @click="changeRoute"
                  :buttonLabel="text.startButtonLabel"
                  :buttonSize="'small'"
                  :buttonStyle="'blue'"
          />
        </div>

      </div>

      <div class="about-slider__navigation" v-if="active.title !== last.title">
        <Button v-if="active.next"
                class="about-slider__navigation__skip"
                @click="changeRoute()"
                :buttonLabel="text.skipButtonLabel"
                :buttonSize="'small'"
                :buttonStyle="'no-outline'"
        />
 
        <div ref="dots" class="about-slider__dots">
          <div v-for="dot in textGroups" :key="dot.title"
               @click="changeGroup(dot.title)"
               class="about-slider__dot"
               :class="{ 'about-slider__dot--active': active.title === dot.title }"
          >
          </div>
        </div>

        <Button v-if="active.next"
                @click="changeGroup(active.next)"
                :buttonLabel="text.nextButtonLabel"
                :arrowRight="true"
                :buttonStyle="'no-outline'"
                class="about-slider__button--next"
        />

      </div>

      <div v-if="active.title === last.title" class="about-slider__redo">
        <Button
            class="about-slider__redo__button"
            @click="changeGroup(first.title)"
            :buttonLabel="text.againLabel"
            :buttonStyle="'no-outline'"
        />
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "AboutSlider.vue",
  data() {
    return {
      text: null,
      textGroups: {},
      active: {},
      first: {},
      last: {},
      loading: true
    }
  },
  methods: {
    async ajaxGetText() {
      return await this.$api.text.get('start')
          .then(data => {

            this.text = data.data;
            this.loading = false;

            if (this.text) {
              this.initSlider();
            }
          });
    },

    initSlider() {

      this.text.texts.forEach((element, index) => {

        // set the next and previous element for the current element
        // we do this to make navigating possible in the template
        let next = this.text.texts[index + 1];
        let previous = this.text.texts[index - 1];

        if (next) element.next = next.title;
        if (previous) element.previous = previous.title;

        if (index === 0) {
          this.active = element;
          this.first = element;
        }

        if (index === this.text.texts.length - 1) {
          this.last = element;
        }

        this.textGroups[element.title] = {...element};
      });
    },

    changeGroup(to) {

      if (!to) return;

      // set the active element based on whether we receive next or previous
      this.active = this.textGroups[to];
    },

    changeRoute() {

      this.$router.push({ name: 'GetStarted' });
    }
  },
  created() {
    this.ajaxGetText();
  }
}
</script>
<template>

  <div class="form-modal"
       :class="[
                 { 'form-modal--open': visible },
             ]"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <div v-if="visible" class="form-modal__form">

            <a @click="$emit('closeModal')" class="form-modal__close">
              <Icon
                  :iconSize="'small'"
                  :iconColor="'transparent'"
                  :iconClass="'cancel'"
              />
            </a>

            <FormSlider v-if="formType === 'slider'"
                        :ajaxPath="formPath"
                        :ajaxParams="formParams"
                        @post-success="this.$emit('postSuccess')"
            />
            <Form v-else
                  :ajaxPath="formPath"
                  :ajaxParams="formParams"
                  @post-success="this.$emit('postSuccess')"
            />
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FormSlider from "@/components/form/FormSlider";
import Form from "@/components/form/Form";

export default {
  name: 'form-modal',
  props: [
    'formType',
    'formPath',
    'formParams',
    'visible'
  ],
  // watch: {
  //   visible: function() {
  //
  //     if (this.visible === true) {
  //       console.log('opening modal');
  //       this.openingModal = true;
  //     }
  //
  //     if (this.visible === false) {
  //       console.log('closing modal');
  //       this.closingModal = true;
  //     }
  //   }
  // },
  components: {
    FormSlider,
    Form
  },
  methods: {

    checkTodos(e) {
      // console.log(e);
    }
  }
}
</script>
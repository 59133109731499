<template>
  <EnvelopeHeader :page-title="title" @go-home="emitGoHome" />

  <div class="dashboard-wrapper">

    <div v-if="loading">
      <Loader />
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <AddressList
              :user="user"
              :language="language"
              :goHome="goHome"
              @loading-start="loadingStart"
              @loading-done="loadingDone"
              @set-title="setTitle"
              @reset-go-home="resetGoHome"
          />

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AddressList from "@/components/location/AddressList";
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import Loader from "@/components/layout/Loader";

export default {
  name: 'dashboard-page',
  components: {
    EnvelopeHeader,
    AddressList,
    Loader
  },
  data() {
    return {
      loading: true,
      title: '',
      goHome: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
    language() {
      return this.$store.getters['language/getLanguage'];
    }
  },
  created() {
    this.ajaxGetUser();
  },
  methods: {

    async ajaxGetUser() {
      return await this.$api.user.get()
          .then(data => {

            this.$store.dispatch('user/setUser');
            this.$store.dispatch('language/setLanguage', data.data.locale);

            // when entering dashboard, make sure locale in url is correct
            const href = `${baseHref}` + '/' + data.data.locale + "/dashboard";
            window.history.pushState('hehe', 'web', href);
          });
    },

    loadingStart() {
      this.loading = true;
    },

    emitGoHome() {
      this.goHome = true;
    },

    resetGoHome() {
      this.goHome = false;
    },

    loadingDone() {
      this.loading = false;
    },

    logout() {
      this.$auth.isAuthenticated = 0;
    },

    changeRoute() {
      this.$router.push('/home')
    },

    setTitle(e) {
      this.title = e;
    }
  }
}
</script>

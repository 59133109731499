import { ApiConfig } from '../ApiConfig.js';
import store from "@/store";

export default {

    get(path, locale = '') {

        // check if a locale is set
        if (store.getters['language/getLanguage']) {
            locale = store.getters['language/getLanguage'];
        }

        return fetch(ApiConfig.baseURL + '/' + locale + '/ajax/' + path, {
            method: 'GET',
            headers: ApiConfig.headers
        })
        .then(function (response) {
            if (response.status !== 200) {
                throw response.status;
            } else {
                return response.json();
            }
        });
    }
}
<template>

  <div>
    <div v-for="(time, index) in hours" :key="index" class="availablility__time">

      <span class="availablility__time__from"><span>Van:</span> <input type="time" :value="time.timeFrom" :ref="'from-' + index" @change="updateHours(index)"></span>
      <span class="availablility__time__to"><span>Tot:</span> <input type="time" :value="time.timeTo" :ref="'to-' + index" @change="updateHours(index)"></span>
      <span class="availablility__time__delete" v-if="index !== 0" @click="deleteTime(index)">
        <Icon
          :icon-class="'cancel'"
          :icon-color="'gray'"
        />
      </span>
    </div>

    <div class="availablility__add">
      <a @click="addHours">
        <Icon :icon-class="'plus'" :icon-color="'blue'" /> {{ attributes.addHours }}
      </a>
    </div>
  </div>

</template>

<script>

import Icon from "@/components/ui-kit-elements/Icon";
export default {
  name: 'availability-hours',
  components: {Icon},
  data() {
    return {
      hours: this.times
    }
  },
  props: [
    'index',
    'times',
    'attributes'
  ],
  created() {

    // if times is empty we push a default object to show
    if (this.times.length === 0) {
      this.addHours();
    }
  },
  methods: {

    addHours() {

      this.hours.push({
        timeFrom: '',
        timeTo: ''
      });
    },

    // delete a time
    deleteTime(index) {

      this.hours.splice(index, 1);

      this.$emit('hoursChanged', this.hours);
    },

    updateHours(index) {

      const from = this.$refs['from-' + index][0].value;
      const to = this.$refs['to-' + index][0].value;

      this.hours[index] = {
        timeFrom: from,
        timeTo: to,
      }

      this.$emit('hoursChanged', this.hours);
    }
  },
}
</script>
<template>
<div class="form-loader form-loader--loading">
  <div>
    <img class="form-loader__logo" src="/src/shared/img/vil-logo.png" alt="VIL Logo">
  </div>
</div>
</template>

<script>

export default {
  name: 'loader-component',
}
</script>

<style>
</style>

<template>

    <label @click="setActive" class="radio-label radio-label--checkbox" :for="option.id"
           :class="{ 'radio-label--active': active }">

        <span class="default-radio-icon">

            <span v-if="activeChildren < childrenAmount"
                  class="radio-label__vacation-label"
            ><i class="icon-minus"></i></span>
            <span v-else-if="activeChildren && activeChildren == childrenAmount"
                  class="radio-label__vacation-label"
            ><i class="icon-check"></i></span>

        </span>

        {{ option.title }}
    </label><br>

    <div v-if="active" class="checkbox__indent">
        <div v-for="location in option.locations" :key="location.id">
            <VacationLocation
                :location="location"

                @add-location="addLocation"
                @remove-location="removeLocation"
            />
        </div>
    </div>

    <input
        :id="option.id"
        :value="true"
        type="checkbox"
        ref="input"
        hidden
    >

</template>

<script>
import VacationLocation from "@/components/form/inputs/custom/vacation/VacationLocation";
export default {
    name: 'Vacation-option',
    components: {VacationLocation},
    data() {
        return {
            active: true,
            childrenAmount: this.option.locations.length,
            activeChildren: 0
        }
    },
    props: [
        'option'
    ],
    mounted() {

        if (this.activeChildren === 0) {
            this.active = false;
        }
    },
    methods: {

        setActive() {
            this.active = !this.active;
            this.$refs.input.value = this.$refs.input.value === 'false';

            if (! this.active) {
                this.removeAllLocations();
            }
        },

        addLocation(id) {
            this.activeChildren++;
            this.$emit('addLocation', id);
        },

        removeLocation(id) {
            this.activeChildren--;
            this.$emit('removeLocation', id);

            if (this.activeChildren === 0) {
                this.active = false;
            }
        },

        removeAllLocations() {

            this.option.locations.forEach(option => {

                this.removeLocation(option.id);
            });
        }
    }
}
</script>
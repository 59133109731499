<template>

  <div class="form" v-if="fetchFailed || (form && ajaxPath)">

    <div v-if="loading">
      <Loader />
    </div>

    <h2 v-if="activeTitle && review === null" class="form__title">{{ activeTitle }}</h2>
<!--    <p class="form__description">{{ response.descr }}</p>-->

    <div class="form-group-nav-wrapper" v-if="!fetchFailed">
      <!--          <a v-for="item in formGroups" :key="item.id"-->
      <!--             @click="nextGroup(item.id)" class="form-group__nav"-->
      <!--             :class="{ 'form-group__nav&#45;&#45;error': item.hasError, 'form-group__nav&#45;&#45;active': item.id === activeGroup }">-->
      <!--            {{ item.step }}-->
      <!--          </a>-->

      <div class="form-group-nav__progress">
            <span class="form-group-nav__progress__inner"
                  :style="[{ 'width': progress + '%' }, 'transition: 0.2s ease']"
            ></span>
      </div>
    </div>

    <form v-if="review === null" @submit.prevent="submitForm" :class="{ 'form--loading': loading }">

      <div class="form-group-wrapper">

        <FormFailed v-if="fetchFailed" :fetchData="response" />

        <div v-for="item in formGroups" :key="item.id">

          <div
              v-if="item.id === formProgressManager.active"
              class="form-group form-grid"
              :class="{ 'form-group--hidden': item.id !== formProgressManager.active }"
              :ref="item.id">

            <!--          <h2>{{ item.title }}</h2>-->

            <section v-for="widget in item.widgets" :key="widget.id"
                     :class="'form-grid--' + widget.width">

              <Input v-if="formValuesVisibility[widget.id]"
                     @validate-control="validateControl"
                     @update-input="updateInput"
                     :v-model="formValues[widget.id]"
                     :ref="widget.id"

                     :inputId="widget.id"
                     :inputType="widget.type"
                     :inputValue="formValues[widget.id]"
                     :inputOptions="widget.formControl.selectOptions"
                     :inputLabel="widget.formControl.title"
                     :inputError="widget.formControl.error"
                     :inputHelp="widget.formControl.helpText"
                     :inputHidden="widget.formControl.hidden"
                     :inputAttributes="widget.attributes"
                     :inputRequired="widget.formControl.required"
                     @keydown.enter.prevent="nextGroup(item.next)"
              />

            </section>

            <section class="form-group__buttons">
              <div class="form__asterix__help">
                * {{ mandatoryHelpText }}
              </div>

              <div class="form-slider__buttons">
                <div v-if="item.previous" @click="previousGroup(item.previous)">
                  <Button
                      :buttonLabel="item.previousButtonLabel"
                      :buttonStyle="'outline'"
                      :arrowLeft="true"
                      :buttonSize="'small'"
                  />
                </div>

                <div v-if="item.next" @click="nextGroup(item.next)" class="form-group__buttons__next">
                  <Button
                      :buttonLabel="item.nextButtonLabel"
                      :buttonStyle="'blue'"
                      :arrowRight="true"
                      :buttonSize="'small'"
                  />
                </div>
                <div v-else class="form__button-wrapper--login form-group__buttons__next">
                  <button class="form__button" @keydown="enter">
                    <Button
                        :buttonLabel="item.nextButtonLabel"
                        :buttonStyle="'blue'"
                        :buttonSize="'small'"
                    />
                  </button>
                </div>
              </div>

            </section>

          </div>

        </div>
      </div>

    </form>
  </div>
  <FormReviewStep v-if="review"
                  class="form"
                  @review-done="finishReview"
                  @review-cancel="this.review = null"
                  :reviewData="review"
                  :buttonLabelSubmit="response.form.submitButtonLabel"
                  :buttonLabelPrevious="response.overviewPreviousButtonLabel"
  />

</template>

<script>
import Input from "@/components/form/inputs/Input";
import Loader from "@/components/layout/Loader";
import FormFailed from "@/components/form/FormFailed";
import FormReviewStep from "@/components/form/FormReviewStep";

/**
 * Form.vue
 * This component generates the forms we receive from the backend
 */
export default {
  name: 'form-slider-component',
  components: {
    FormReviewStep,
    FormFailed,
    Loader,
    Input
  },
  props: [
    'ajaxPath',
    'ajaxParams'
  ],
  data() {
    return {

      /**
        --- API PROPERTIES ---
      */

      /*
       * The complete response data (not just the form)
       */
      response: null,

      /*
       * The form we load via ajax (only form to avoid enormous property chains ( thank me later! ;-) ))
       */
      form: null,

      /*
       * Set the form states
       */
      states: null,

      /*
       * formValues
       *
       * This property contains all the v-model values we need for the loaded form
       * This property will automatically be filled whenever the form is loaded in ajaxGetForm()
       */
      formValues: {},

      fetchFailed: false,

      /**
        --- RENDERING & WORKING PROPERTIES ---
      */

      /*
       * All our formGroups
       */
      formGroups: {},

      formProgressManager: {
        active: null,
        length: 0,
        groupErrors: [],
        groupIds: []
      },

      /*
       * The title that's currently visible in the template
       */
      activeTitle: null,

      /*
       * Working objects to determine if inputs or even entire groups should be visible
       */
      formValuesVisibility: {},
      formGroupVisibility: {},

      /*
       * Working property to determine which group is currently active
       */
      activeGroup: '',

      progress: 0,
      loading: true,

      /*
       * Working array, as we build our formGroups object we also fill this property with the inputs we
       * have already handled, this way we can use this object to determine which inputs don't have a group
       * and still need to be handled
       */
      handledInputIds: [],

      /*
        --- CUSTOM PROPERTIES ---
      */

      hasReviewStep: false,
      review: null,
      mandatoryHelpText: `${mandatoryHelpText}`,

      /**
       --- VALIDATION PROPERTIES ---
      */

      /*
       * validateModel
       *
       * This property is used to do real-time validation
       * The values will be updated whenever a control is validated in validateControl(e)
       */
      validateModel: {
        widgetId: '',
        formValues: null
      }
    }
  },
  methods: {

    /**
     * --- API METHODS ---
     */

    /*
     * Load the registration form via ajax
     */
    async ajaxGetForm() {

      return await this.$api.form.get(this.ajaxPath, this.ajaxParams)
          .then(data => {

            if (data.code === 403) {
              this.fetchFailed = true;
              this.response = data;
              this.setIsLoading(false);
              return;
            }

            this.initSliderForm(data);

            // console.log('Get successful');
            this.setIsLoading(false);

            this.$emit('get-title', data.title);
          })
          .catch(error => {
            console.log(error);
          });
    },

    /*
     * Validate a control when it loses focus (real time)
     *
     * This method performs a 'ping pong' with the backend to check if the value is valid
     * We do this to prevent submit errors (nobody likes submit errors)
     */
     async validateControl(id, value) {

       // set variables to the values from input
       const widgetId = id;
       const formValues = { [id]: value };

       // update the validateModel to send to backend with the variables we just made
       this.validateModel.widgetId = widgetId;
       this.validateModel.formValues = formValues;

       // post our validateModel to the validateControl route for this form
       const result = await this.$api.form.validate(JSON.stringify(this.validateModel), this.ajaxPath);

       this.handleWidgetErrors(result, widgetId);

       // if we're dealing with the confirm password widget we need to do some front end validation
       if (widgetId === 'confirmPassword') {
         this.confirmPassword(value);
       }

       return true;
     },

    /*
     * Submit the form
     *
     * @returns {Promise<*>}
     */
    async submitForm() {
      this.setIsLoading(true);

      return await this.$api.form.post(JSON.stringify({ 'formValues': this.formValues }), this.ajaxPath, this.ajaxParams)
          .then(data => {

            if (!data.success) {
              this.setIsLoading(false);

              // if we have errors we can replace our form with the form the backend sends back
              this.form = data.form;

              // because AppKit is super cool it sends our form back with errors and values intact
              // that's why we can just rebuild the form with the form we received back
              return this.initSliderForm(data);
            }

            // console.log('Post successful');
            this.setIsLoading(false);

            // trigger message (if there is one)
            data.todos.forEach((todo) => {

              if (todo.eventName === 'review' && todo['eventData']['dataObjectId']) {
                this.reviewStep(todo['eventData']['dataObjectId']);
              }

              if (todo['type'] === 'message') {

                this.$store.dispatch('message/setMessage', todo['text']);
              }
            });

            if (this.hasReviewStep) return;

            // emit todos along with the post-success event, some forms need this response data for routing purposes
            this.$emit('post-success', data.todos);
          });
    },

    /*
     * If the form has a review step, we handle it here
     */
    async reviewStep(id) {

      this.hasReviewStep = true;

      return await this.$api.location.get(id)
          .then(data => {

            this.review = data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },

    /*
     * Emit that the review step has been completed
     */
    finishReview() {
      this.$emit('post-success');
    },

    /**
     * --- FORM GROUP METHODS ---
     */

    /*
     * Just like in Form.vue we need to build a form based on the response we get from the backend
     * But we want this form to be made out of steps based on AppKit's formGroups
     *
     * After performing an API call you can call this method and pass the response from you API call
     * The code below will generate a formGroup data property within this component with all the groups and their widgets
     *
     * Afterwards, we can loop over this object in the template and have access to all necessary properties
     */
    initSliderForm(data) {

      // set response
      this.response = data;

      // fill formValues with the value of each widget
      data.form.widgets.forEach((item) => {
        this.formValues[item.id] = item.formControl.value;
        this.formValuesVisibility[item.id] = true;
      });

      // set the form
      this.form = data.form;
      this.states = data.form.states;

      this.handleStates();

      // loop over the form groups to create our own object
      data.form.layout.groups.forEach((formGroup, index) => {

        this.createGroup(formGroup, index, data);
      });

      this.formProgressManager.groupIds = Object.keys(this.formGroups);
      this.formProgressManager.length = Object.keys(this.formGroups).length;
      this.formProgressManager.active =  this.formProgressManager.groupIds[0];

      console.log(this.formProgressManager.groupErrors);
      console.log(this.formProgressManager.groupErrors[0]);

      if (this.formProgressManager.groupErrors[0]) {
        this.formProgressManager.active = this.formProgressManager.groupErrors[0];
      }
      this.handleNonGroupInputs();

      window.scrollTo(0,0);
      this.progressBar(0);
    },

    /*
     * Create a formGroup
     */
    createGroup(formGroup, index, data) {

      formGroup.hasError = false;

      formGroup.step =  formGroup.groupLabel + ' ' + (index + 1);
      formGroup.index =  index;

      // add a next and the previous property with the id of the next/previous group (for changing active group)
      formGroup.next = data.form.layout.groups[index+1] ? data.form.layout.groups[index+1].id : false;
      formGroup.previous = data.form.layout.groups[index-1] ? data.form.layout.groups[index-1].id : false;

      // set the first group active in the template
      if (index === 0) {
        this.activeGroup = formGroup.id;
        this.activeTitle = formGroup.title;
      }

      // add a widgets property to the group
      formGroup.widgets = {};

      // then fill it with the form widgets that belong in this group
      formGroup.widgetIds.forEach((id) => {

        this.handledInputIds.push(id);

        // get the input
        const widget = this.getInput(id);

        if (widget[0] && index !== 0 && this.activeGroup !== formGroup.id && widget[0].formControl.hasError) {

          this.activeGroup = formGroup.id;
          this.activeTitle = formGroup.title;
        }

        if (! widget || ! widget[0]) {
          return;
        }

        // set the widget width
        widget[0].width = 12;

        if (this.form.layout.widthContainer[id]) {
          widget[0].width = this.form.layout.widthContainer[id];
        }

        // check if there are errors
        if (widget[0] && widget[0].formControl.hasError === true && formGroup.hasError === false) {
          formGroup.hasError = true;
          this.formProgressManager.groupErrors.push(formGroup.id);
        }

        this.formGroups[formGroup.id] = {...widget};
        this.formGroups[formGroup.id] = {...formGroup};
        this.formGroupVisibility[formGroup.id] = true;

        // create our own object for looping in the template
        this.formGroups[formGroup.id].widgets = this.form.widgets.filter(x => formGroup.widgetIds.includes(x.id));
      });
    },

    /*
     * Simply add all the 'ungrouped' widgets to the last group
     */
    handleNonGroupInputs() {

      this.form.widgets.forEach(widget => {

        if (this.handledInputIds.includes(widget.id)) return;

        let lastFormGroupId = Object.keys(this.formGroups).pop();

        widget.width = 12;
        this.formGroups[lastFormGroupId].widgets.push(widget);
      });
    },

    /*
     * Handle the formStates
     * AppKit forms can send formStates, these can be used to show or hide widgets when certain conditions are met
     *
     * In this method we can continuously check if these conditions are met and then alter the form accordingly
     */
    handleStates() {

      this.states.forEach(state => {

        if (state.visibility === undefined) return;

        let conditionResults = [];

        // loop over every condition in the current state
        state.conditions.forEach((condition) => {

          // set the parameters needed to determine the outcome of the condition
          // check the response in the browser for more information on these conditions :-)
          const widgetId = condition[0];
          const value = condition[1];
          const operator = condition[2];
          const currentValue = this.formValues[widgetId];

          // check if the provided condition matches anything
          const result = this.$operationHandler(currentValue, operator, value).result();

          // add result to array
          conditionResults.push(result);
        });

        // now we have the results of every condition in this state stored in an array
        // if one of the stored results is false, this means not all conditions are met and thus we don't show the widget
        // if not false value is found, we can show the widget
        let result = conditionResults.includes(false);

        Object.keys(state.visibility).forEach((id) => {

          if (result === false) {
            this.formValuesVisibility[id] = true;
          }
          else {
            this.formValuesVisibility[id] = false;
          }
        });
      });
    },

    /*
     * Validate all widgets in a group
     *
     * We loop over every widget in a group and if its value is empty and required
     * Validate it
     */
    async validateGroup() {

      this.setIsLoading(true);

      let validated = true;

      // we get the current active group
      const groupId = this.formProgressManager.active;
      const groupObject = this.formGroups[groupId];

      // loop over the widgets and check if they should be validated
      // if so, validate them
      for (const widgetKey in groupObject.widgets) {
        const widget = groupObject.widgets[widgetKey];

        // if the widget isn't filled in properly while it's required
        if (this.formValues[widget.id] === null && widget.formControl.required
            ||
            this.formValues[widget.id] === '' && widget.formControl.required
        ) {

          // validate the widget
          const result = await this.validateControl(widget.id, this.formValues[widget.id]);

          validated = false;
        }
      }

      this.setIsLoading(false);
      return validated;
    },

    /*
     * Check if group has any visible widgets
     * If so, show it, else we skip it to the next group after it
     */
    async nextGroup() {

      this.handleStates(); // handle states and stuff

      // validate the group before moving on
      const validate = await this.validateGroup();
      if (!validate) {
        return;
      }

      // get the next groupId based on our formProgressManager property
      const id = this.formProgressManager.groupIds.indexOf(this.formProgressManager.active);
      const next = this.formProgressManager.groupIds[id + 1];

      // get the next formGroup from our formGroups property
      const formGroup = this.formGroups[next];


      // make array of the visibility of the widgets in the next group
      const skipArray = [];

      this.formGroups[next].widgets.forEach((widget) => {
        skipArray.push(this.formValuesVisibility[widget.id]);
      });

      // if one widget is visible we can just go to the next group
      if (skipArray.find(value => value === true)) {

        this.formProgressManager.active = next;
        this.activeTitle = formGroup.title;

        window.scrollTo(0,0);
        this.progressBar(this.formGroups[next].index);
        this.setIsLoading(false);
        return;
      }

      // else we skip it
      // const skip = this.formGroups[formGroup.next].id;
      this.formProgressManager.active = next;
      window.scrollTo(0,0);
      this.progressBar(this.formGroups[next].index);

      this.nextGroup();
    },

    /*
     * Check if group has any visible widgets
     * If so, show it, else we skip it to the previous group before it
     */
    previousGroup() {

      const id = this.formProgressManager.groupIds.indexOf(this.formProgressManager.active);
      const previous = this.formProgressManager.groupIds[id - 1];

      const formGroup = this.formGroups[previous];

      const skipArray = [];

      // make array of the visibility of the widgets in the next group
      this.formGroups[previous].widgets.forEach((widget) => {
        skipArray.push(this.formValuesVisibility[widget.id]);
      });

      // if one widget is visible we can just go to the next group
      if (skipArray.find(value => value === true)) {
        this.formProgressManager.active = previous;
        this.activeTitle = formGroup.title;
        window.scrollTo(0,0);

        this.progressBar(this.formGroups[previous].index);
        return;
      }

      // else we skip it
      // const skip = this.formGroups[formGroup.next].id;
      this.formProgressManager.active = previous;
      window.scrollTo(0,0);
      this.progressBar(this.formGroups[previous].index);

      this.previousGroup();
    },

    /*
     * Update the progressbar
     */
    progressBar(progressPercentage) {

      const stepAmount = Object.keys(this.formGroups).length;

      let percentage = (100 / (stepAmount - 1)) * progressPercentage;
      this.progress = parseInt(percentage);
    },

    handleWidgetErrors(data, id) {

      const element = document.querySelector('#' + id);

      // if we have an error add right classes, and vice-versa
      if (data[id] && data[id].hasError) {
        element.classList.add('input--error');
      }
      else {
        element.classList.remove('input--error');
      }

      // add the error message (if we don't have one, nothing will render)
      element.nextSibling.innerText = data[id].error;

      this.handleStates(id);
    },

    confirmPassword(value) {
      const password = document.querySelector('#password');
      const confirmPassword = document.querySelector('#confirmPassword');

      if (value !== password.value) {

        confirmPassword.classList.add('input--error');
        confirmPassword.nextSibling.innerText = `${confirmPasswordError}`;
      }
      else {
        confirmPassword.classList.remove('input--error');
        confirmPassword.nextSibling.innerText = '';
      }
    },

    /**
     * --- INPUT METHODS ---
     */

    /*
     * Get an input from our form based on the given widgetId
     */
    getInput(widgetId) {
      const widget = this.form.widgets.filter(input => input.id === widgetId);

      if (! widget) return;

      return this.form.widgets.filter(input => input.id === widgetId);
    },

    /*
     * When we change the input, our v-model needs to change too
     * That's why we set the v-model equal to the value of the value of the input
     *
     * This method is called whenever the input changes, this way we can change the v-model in real-time
     */
    updateInput(id, inputValue, dataset) {

      // check if the target has an isObject attribute, if so, we need to parse it
      if (dataset !== null && dataset.isObject) {
        inputValue = JSON.parse(inputValue);
      }

      this.formValues[id] = inputValue;
    },

    setIsLoading(isLoading) {
      this.loading = isLoading;
      // Vue.set(loading)
    }
  },
  created() {
    // load the form
    this.ajaxGetForm();
  }
}
</script>
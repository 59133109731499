<template>
  <div>
    <EnvelopeHeader :page-title="title" />

    <EditLocationForm
        @get-title="setTitle"
    />
  </div>
</template>

<script>

import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import EditLocationForm from "@/components/form/EditLocationForm";

export default {
  name: 'edit-location',
  data() {
    return {
      title: null
    }
  },
  components: {
    EditLocationForm,
    EnvelopeHeader,
  },
  methods: {

    setTitle(title) {
      this.title = title;
    }
  }
}
</script>

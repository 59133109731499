<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <Form
              :ajaxPath="'user/recovery'"
              :ajaxParams="generateUrlParameters()"
              @post-success="redirect"
              @get-title="emitTitle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
  name: 'password-recovery-form',
  components: {
    Form
  },
  methods: {

    /**
     * Generate a custom parameter string to add to the requests
     *
     * @returns {string}
     */
    generateUrlParameters() {
      // console.log(this.$route.params)
      return '?token=' + this.$route.params.token;
    },

    redirect() {

      // go to our login
      this.$router.push({ name: 'Login' });
    },

    emitTitle(title) {

      this.$emit('get-title', title);
    }
  }
}
</script>
<template>

  <div class="availablility-wrapper availablility-wrapper__always">
    <span>{{ inputAttributes.alwaysAvailableFlag }}</span>
    <Toggle ref="alwaysAvailable" @click="setAlwaysAvailable" :toggleProp="this.availabilities.alwaysAvailableFlag" />
  </div>

  <div :class="{ 'no-display': availabilities.alwaysAvailableFlag === 1 }">

    <div v-for="availability in availabilities.availabilities" :key="availability.weekday">

      <AvailabilityDay @day-changed="emitUpdate"
          :availability="availability"
          :attributes="inputAttributes" />

    </div>

  </div>

  <p class="input-error">{{ inputError }}</p>
</template>

<script>
import AvailabilityDay from "@/components/form/inputs/custom/availabilities/AvailablilityDay";

export default {
  name: 'availabilities-inputs',
  components: {
    AvailabilityDay
  },
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError',
    'inputOptions',
    'inputAttributes'
  ],
  data() {
    return {
      availabilities: this.inputValue,
    }
  },
  mounted() {

    // make sure the 'alwaysAvailableFlag' is set properly
    if (this.availabilities.alwaysAvailableFlag === 1 || this.availabilities.alwaysAvailableFlag === undefined) {
      this.availabilities.alwaysAvailableFlag = 1;
    }
    else {
      this.availabilities.alwaysAvailableFlag = 0;
    }
  },
  methods: {

    /**
     * If the element should be hidden, hide it
     * Called in mounted
     */
    hidden() {
      this.$refs.input.hidden = true;
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     */
    emitValidate() {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: this.availabilities,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
      },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     */
    emitUpdate() {

      // construct an object with the params we need to update the widget
      const update = {
        value: this.availabilities,
        id: this.inputId,
        dataset: null
      }

      this.$emit('update-input', update);
      },

    setAlwaysAvailable() {

      if (this.availabilities.alwaysAvailableFlag === 1) this.availabilities.alwaysAvailableFlag = 0;
      else this.availabilities.alwaysAvailableFlag = 1;

      this.emitUpdate();
    },

    // updateInput() {
    //   this.$refs.input.value = JSON.stringify(this.availabilities);
    //   this.$refs.input.click();
    // }
  }
}
</script>
<template>
    <div class="form-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <Form
                        :ajaxPath="'vacation-period/form'"
                        @get-title="emitTitle"
                        @post-success="$router.push({ name: 'HolidayGrid' })"
                    />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
    name: 'vacation-entry-form',
    components: {
        Form
    },
    methods: {

        emitTitle(title) {

            this.$emit('get-title', title);
        }
    }
}
</script>
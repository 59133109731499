<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <Form
              :ajaxPath="'address/form'"
              :ajaxParams="generateUrlParameters()"
              :extraText="text"
              @post-success="redirect"
              @get-title="emitTitle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
  name: 'login-form',
  data() {
    return {
      text: null,
    }
  },
  components: {
    Form
  },
  methods: {

    /**
     * Generate a custom parameter string to add to the requests
     *
     * @returns {string}
     */
    generateUrlParameters() {
      return '?id=' + this.$route.params.hashId;
    },

    redirect() {

      // go to our dashboard
      this.$router.push({ name: 'Dashboard' });
    },

    emitTitle(title) {

      this.$emit('get-title', title);
    }
  }
}
</script>
import { Api } from "@/api/Api";

// initial state
const state = () => ({
    messages: [],
    user: {},
    language: '',
    place: {
        streetName: null,
        streetNumber: null,
        city: null
    }
})

// getters
const getters = {

    getMessages(state) {
        return state.messages
    },

    getUser(state) {
        return state.user
    },

    getLanguage(state) {
        return state.language
    },

    getPlace(state) {
        return state.place
    }
}

// actions
const actions = {
    // async ajaxGetText({ commit }) {
    //     const text = await Api.text.start();
    //     commit('setText', text.data);
    // },
    async setUser({ state }) {
        state.user = await Api.user.get();
    },
    setLanguage({ state }, language) {
        state.language = language;
    },
    setMessage({ state }, message) {
        state.messages.push(message);
    },
    deleteMessage({ state }) {
        state.messages.shift();
    },
    setPlace({ state }, payload) {

        // switch (payload.id) {
        //     case 'streetName':
        //         state.place.streetName = payload.value;
        //         break;
        //     case 'streetNumber':
        //         state.place.streetNumber = payload.value;
        //         break;
        //     case 'city':
        //         state.place.city = payload.value;
        //         break;
        //      }
        state.place = payload;
    },
    deletePlace({ state }) {
        // state.place.streetNumber = null;
        // state.place.streetName = null;
        // state.place.city = null;
        state.place = null;
    }
}

// mutations
const mutations = {
    // setText(state, text) {
    //     state.text = text;
    //     console.log(state.text);
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
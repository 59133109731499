<template>
  <div>
    <EnvelopeHeader :page-title="title" />

    <ProfileForm
        @get-title="setTitle"
    />
  </div>
</template>

<script>

import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import ProfileForm from "@/components/form/ProfileForm";

export default {
  name: 'register-page',
  data() {
    return {
      title: null
    }
  },
  components: {
    ProfileForm,
    EnvelopeHeader,
  },
  methods: {
    changeRoute() {
      this.$router.push('/dashboard');
    },
    setTitle(title) {
      this.title = title;
    },
  }
}
</script>

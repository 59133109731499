import Icon from '@/components/ui-kit-elements/Icon.vue'
import Button from '@/components/ui-kit-elements/Button.vue'
import Toggle from "@/components/ui-kit-elements/Toggle";

/**
 * UI Kit Elements
 *
 * Install the global components
 */
export default {
    install: (app) => { // eslint-disable-line

        app.component("Icon", Icon); // eslint-disable-line
        app.component("Button", Button); // eslint-disable-line
        app.component("Toggle", Toggle); // eslint-disable-line
    },
};
<template>

    <div v-if="notification" class="notification-popup">
        <div class="container">
            <div class="row">
                <div class="col-md-10 offset-md-1">

                    <div class="notification-popup__inner">
                        <a @click="closePopup"><i class="icon-cancel"></i></a>
                        <h3>{{ notification.title }}</h3>
                        <div class="notification-popup__inner__date">{{ notification.date }}</div>
                        <p>{{ notification.body }}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'NotificationComponent',
    data() {
        return {
            notification: null
        }
    },
    props: [
        'activeId'
    ],
    watch: {
        activeId() {
            this.ajaxGetNotification();
        }
    },
    methods: {

        async ajaxGetNotification() {

            if (this.activeId === null) {
                return;
            }

            return await this.$api.notification.get(this.activeId)
                .then(data => {
                    this.notification = data.data;

                })
                .catch(error => {
                    console.log(error);
                });
        },

        closePopup() {
            this.notification = null;
            this.$emit('close-notification');
        }
    }
}
</script>
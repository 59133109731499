<template>
  <div>
    <EnvelopeHeader :page-title="title" />
    <LoginForm
        @get-title="setTitle"
    />
  </div>
</template>

<script>
import EnvelopeHeader from "@/components/layout/EnvelopeHeader";
import LoginForm from "@/components/form/LoginForm";

export default {
  name: 'login-page',
  data() {
    return {
      title: null
    }
  },
  components: {
    LoginForm,
    EnvelopeHeader
  },
  methods: {
    logout() {
      this.$auth.isAuthenticated = 0;
    },
    changeRoute() {
      this.$router.push({ name: 'Dashboard' })
    },
    setTitle(title) {
      this.title = title;
    }
  },
  created() {
    if (this.$auth.isAuthenticated === 1) {
      this.changeRoute();
    }
  }
}
</script>

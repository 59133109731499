<template>

  <h2 class="form__title--error">{{ fetchData.message }}</h2>
<!--  <a @click="this.$router.push({ name: 'Dashboard' })">-->
<!--    <Button-->
<!--        :buttonLabel="fetchData.backButtonLabel"-->
<!--        :buttonStyle="'blue'"-->
<!--        :buttonSize="'small'"-->
<!--    />-->
<!--  </a>-->
</template>

<script>

export default {
  name: 'form-failed',
  props: [
    'fetchData'
  ]
}
</script>
<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <Form
              :ajaxPath="'user/activate'"
              @post-success="redirect"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
  name: 'activate-form',
  components: {
    Form
  },
  methods: {
    // redirect to next page and add necessary param userId
    redirect() {

      this.$router.push({ name: 'Dashboard' });
    },
  }
}
</script>
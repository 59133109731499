<template>

  <div :class="['input-wrapper', 'input-wrapper-' + inputId]">

    <div class="input-label">
      <label :for="inputId" :class="'input-label-' + inputId">
        {{ inputLabel }}
        <span v-if="inputRequired">*</span>
      </label>
    </div>

    <component
        :is="inputType"
        :id="inputId"
        :inputId="inputId"
        :inputValue="inputValue"
        :inputError="inputError"
        :inputOptions="inputOptions"
        :inputHidden="inputHidden"
        :inputHelp="inputHelp"
        :inputAttributes="inputAttributes"

        class="input"
        @validate-control="emitValidate"
        @update-input="emitUpdate"
    >
    </component>

    <p v-if="inputType !== 'ak-checkbox' && ! alteredInputHelp" class="input-help" v-html="inputHelp"></p>
    <p v-else-if="inputType !== 'ak-checkbox' && alteredInputHelp" class="input-help" v-html="alteredInputHelp"></p>

  </div>

</template>

<script>

/**
 * Input.vue
 * This component generates inputs based on what we receive from the backend
 *
 * Note: the <component> tag will be replaced by a custom input from ./custom
 *       If you want to add a new custom input type you'll also need to register it in CustomInputs.js!!
 */
export default {
  name: 'Input-field',
  data() {
    return {
      alteredInputHelp: null
    }
  },
  props: [
    'inputId',
    'inputType',
    'inputRequired',
    'inputValue',
    'inputOptions',
    'inputHidden',
    'inputLabel',
    'inputError',
    'inputHelp',
    'inputAttributes'
  ],
  created() {
    if (this.inputHelp && this.inputHelp.includes('#hrefMoveForm')) {
      const href = `${baseHref}` + '/' + this.language + '/dashboard/moving';
      this.alteredInputHelp = this.inputHelp.replace('#hrefMoveForm', href); 
    }
  },
  computed: {
    language() {
      return this.$store.getters['language/getLanguage'];
    }
  },
  methods: {

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     * param e contains an id and a value which we assemble in the control template
     *
     * @param e
     */
    emitValidate(e) {
      this.$emit('validate-control', e.id, e.value);
    },


    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     * param e contains an id, a dataset and a value which we assemble in the control template
     *
     * @param e
     */
    emitUpdate(e) {
      this.$emit('update-input', e.id, e.value, e.dataset);
    }
  }
}
</script>
<template>

  <input
      @blur="emitValidate"
      @click="emitUpdate"

      :id="inputId"
      :required="inputRequired"
      :value="inputValue"

      hidden

      type="text"
      ref="input"
      maxlength="6"
      minlength="6"
  >

  <div class="activation-inputs">

    <input
        @input="updateHiddenInput"
        @paste="handlePaste"
        @click="autoFocusSecond"

        type="text"
        ref="inputFirst"
        max="999"
        maxlength="3"
    >

    <input
        @input="updateHiddenInput"
        @paste="handlePaste"
        @keydown.delete="autoFocusFirstOnBackspace"
        @click="autoFocusFirstOnInput"
        type="text"
        ref="inputSecond"
        max="999"
        maxlength="3"
    >

  </div>

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
/**
 * This input uses one 'main' input which is hidden
 * The 2 other inputs are used to 'feed' the main input (this is purely a cosmetic thing btw)
 */
export default {
  name: 'input-activation-code',
  props: [
    'inputId',
    'inputRequired',
    'inputValue',
    'inputError'
  ],
  mounted() {
    // the route param values are already handled in Input.vue but we still need to paste them in the working inputs here
    if (this.$route.params.activationCode) {
      this.handleRouteParams(this.$route.params.activationCode);
    }
  },
  methods: {

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e.target.value,
        id: this.inputId,
        dataset: e.target.dataset
      }

      this.$emit('update-input', update);
    },

    /**
     * Updates the value of the hidden input based on the clicked radio
     */
    updateHiddenInput() {

      const valueFirst = this.$refs.inputFirst.value;
      const valueSecond = this.$refs.inputSecond.value;

      this.$refs.input.value = valueFirst + valueSecond;

      // trigger a click on the hidden input to trigger the emitUpdate function on the hidden input
      // a bit dirty I know, but it works, fight me
      this.$refs.input.click();
      this.$refs.inputFirst.click();
      this.$refs.inputSecond.click();
      this.handleInputText();
    },

    /**
     * autoFocusSecond & autoFocusFirst
     * To make the transition between the 2 inputs seamless we perform some black magic
     * JK, we just check the length and change the focus to another element and stuff like that :lol:
     */
    autoFocusSecond() {
      if (this.$refs.inputFirst.value.length === 3) {
        this.$refs.inputSecond.focus();
      }
    },
    autoFocusFirstOnBackspace() {
      if (this.$refs.inputSecond.value.length === 0) {
        this.$refs.inputFirst.focus();
      }
    },
    // @todo fix this
    autoFocusFirstOnInput() {
      if (this.$refs.inputFirst.value.length !== 3) {
        this.$refs.inputFirst.focus();
      }
    },
    handleInputText() {
      const valueFirst = this.$refs.inputFirst.value;
      const valueSecond = this.$refs.inputSecond.value;
      const value = valueFirst + valueSecond;

      this.$refs.inputFirst.value = value.substring(0, 3);
      this.$refs.inputSecond.value = value.substring(3, 6);
    },
    handlePaste(e) {
      const pasteData = e.clipboardData.getData('text');

      this.$refs.inputFirst.value = pasteData.substring(0, 3);
      this.$refs.inputSecond.value = pasteData.substring(3, 6);

      this.updateHiddenInput();
    },
    handleRouteParams(code) {

      this.$refs.inputFirst.value = code.substring(0, 3);
      this.$refs.inputSecond.value = code.substring(3, 6);

      this.updateHiddenInput();
    }
  }
}
</script>
<template>

  <div v-for="option in inputOptions" :key="option.label" class="checkboxes">
    <label @click="addToSelected(option)" class="radio-label radio-label--checkbox radio-label--checkboxes"
           :class="{ 'radio-label--active': selectedArray.includes(option.value) }">
      <span class="default-radio-icon"></span>
      {{ option.label }}
    </label>
  </div>

<!--  <input-->
<!--      @click="emitUpdate"-->
<!--      hidden-->

<!--      data-is-object="true"-->
<!--      :value="selectedArray"-->
<!--      :id="inputId"-->
<!--      :required="inputRequired"-->
<!--      type="checkbox"-->
<!--      ref="input"-->
<!--  >-->

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
export default {
  name: 'Input-checkbox',
  data() {
    return {
      selectedArray: []
    }
  },
  props: [
    'inputId',
    'inputRequired',
    'inputError',
    'inputHelp',
    'inputOptions',
    'inputValue'
  ],
  created() {

    // if the get our results back from the backend we need to decode it
    this.selectedArray = this.inputValue
  },
  methods: {

    addToSelected(option) {

      // make sure that only one of each value can be in the array
      const index = this.selectedArray.indexOf(option.value);

      if (index > -1) {
        this.selectedArray.splice(index, 1);
      }
      else {
        // push value to array
        this.selectedArray.push(option.value);
      }
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {
      this.$emit('validate-control', e);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     */
    emitUpdate() {

      // construct an object with the params we need to update the widget
      const update = {
        value: this.selectedArray,
        id: this.inputId,
        dataset: null
      }

      this.$emit('update-input', update);
    },

    updateInput() {
      this.$refs.input.value = JSON.stringify(this.selectedArray);
      this.$refs.input.click();
    }
  }
}
</script>
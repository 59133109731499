import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n'
import { Api } from "@/api/Api";
import { Auth } from "@/auth/auth"
import googleMapsApi from "@/api/GoogleMapsApi"

import Helpers from './helpers/Helpers';
import Animate from './plugins/Animatable/Animatable';
import CustomInputs from './components/form/inputs/CustomInputs';
import UiKitElements from './components/ui-kit-elements/UIKitElements';

import './assets/scss/application.scss';


const app = createApp(App);

// i18n
app.use(i18n);

// router
app.use(router);

// vuex
app.use(store);
app.config.globalProperties.$store = store;

// api
app.config.globalProperties.$api = Api;

const googleMap = googleMapsApi.getGoogleMapsApi();
// api
app.config.globalProperties.$googleMaps = googleMap;

// auth
app.config.globalProperties.$auth = Auth;

// plugins
app.use(Helpers);
app.use(Animate);
app.use(CustomInputs);
app.use(UiKitElements);

app.mount('#app');

<template>

<div v-if="response && !loading && user">

  <div class="dashboard-title">
    <h1>{{ data.title }}</h1>
    <span v-if="user && !isOrganisationUser()"
        class="dashboard-title__add" @click="showModal(null)">
      <Icon
          :iconColor="'red'"
          :iconSize="'small'"
          :iconClass="'plus'"
      />
      <span class="sticky-add-button__inner__label">{{ addAddressLabel }}</span>
    </span>

  </div>

  <div v-for="(address, index) in addresses" :key="address.id">

    <Address @reload="reload"
             @open-address-modal="showModal"
             :addressData="address"
             :showAllButtonLabel="response.data.showAllButtonLabel"
             :addAddressButtonLabel="response.data.addAddressButtonLabel"
             :addLocationButtonLabel="response.data.addLocationButtonLabel"
             :editAddressButtonLabel="response.data.editAddressButtonLabel"
             :deleteAddressLabel="response.data.deleteAddressLabel"
             :movingLabel="response.data.movingLabel"
             :index="index"
             :user="user"
             :goHome="goHome"
    />

  </div>

  <FormModal @close-modal="closeModal"
             @post-success="reload"
             :visible="modalVisible"
             :formPath="'address/form'"
             :formParams="activeLocationParams"
  />

</div>
<!--  v-if="user.data.role.id === this.$auth.userRoleHashId && user.data.account.type !== 2"-->
  <div  class="sticky-add-button">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sticky-add-button__inner" >
            <a @click="showModal(null)">
              <span class="sticky-add-button__inner__label">{{ addAddressLabel }}</span>
              <Icon
                  :iconColor="'red'"
                  :iconSize="'big'"
                  :iconClass="'plus'"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Address from "@/components/location/partials/Address";
import FormModal from "@/components/form/layout/FormModal";

export default {
  name: 'address-list',
  props: [
    'user',
    'language',
    'goHome'
  ],
  data() {
    return {
      response: {},
      loading: true,
      data: {},
      addresses: {},
      modalVisible: false,
      activeLocationParams: '',
      addAddressLabel: '',
      userRole: `${userRole}`,
      organisationTypeId: `${organisationTypeId}`
    }
  },
  components: {
    Address,
    FormModal
  },
  watch: {
    goHome: function () {
      this.$emit('reset-go-home');
      this.closeModal();
    },
    // if the locale changes, re-fetch the data
    language: function() {
        this.reload();
    }
  },
  methods: {

    /**
     * Get the addresses
     */
    async ajaxGetAddressList() {
      this.$emit('loadingStart');

      return await this.$api.address.get('get-all')
          .then(data => {

            // set response
            this.response = data;
            this.data = data.data;

            this.addresses = data.data.adresses; // @todo typo >:-(
            this.addAddressLabel = data.data.addAddressButtonLabel;

            // console.log('Get successful');
            this.$emit('loadingDone');
            this.$emit('setTitle', data.data.title)
            this.loading = false;
          });
    },

    // check if user is an organisation user which is not an admin
    isOrganisationUser() {
      if (this.user.data.account.type === parseInt(this.organisationTypeId)
          &&
          this.user.data.role.id === this.userRole) {
          return true;
      }

      return false;
    },

    /**
     * Show the modal with the correct form
     */
    showModal(id) {

      // if we have an id, we generate params to open an edit address form
      if (id) {
        this.activeLocationParams = this.generateUrlParameters(id);
      }
      // else we open the new address form
      else {
        this.activeLocationParams = '';
      }

      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    /**
     * Generate a custom parameter string to add to the requests
     *
     * @param id
     * @returns {string}
     */
    generateUrlParameters(id) {
      return '?id=' + id;
    },

    /**
     * Reload the addresses
     */
    reload() {
      this.addresses = {};
      this.ajaxGetAddressList();
      this.closeModal();
    },
  },
  created() {
    // get the addresses
    this.ajaxGetAddressList();
  }
}
</script>

<template>

    <draggable class="upload-images"
        v-model="imagesArray"
        data-draggable
        group="images"
        @start="dragStart"
        @end="dragEnd($event)"
        delay="150"
        :delayOnTouchOnly="true"
        item-key="id">
        <template #item="{element}">
            <div :key="element.id" class="upload-image-wrapper" :ref="element.id">

                <span class="upload-image__icons">
                    <i class="icon-drag_indicator"></i>
                    <a href="#" @click="toggleDeletePrompt(element.id)"><i class="icon-trash"></i></a>
                </span>

                <div class="upload-image__image">
                    <img class="upload-image" :src="element.thumbnail" alt="Preview" draggable="false">
                    <span class="upload-image__backdrop"></span>
                </div>
                <div class="upload-image__description">
                    <textarea @input="updateImageDescription($event, element.id)"
                              cols="10" rows="1"
                              :value="element.description"
                              :placeholder="descriptionLabel"
                    />
                </div>

                <div class="upload-image__delete-prompt" :ref="'delete-' + element.id">
                    <Button
                        :buttonLabel="deleteLabel"
                        :arrowLeft="false"
                        :arrowRight="false"
                        :buttonStyle="'outline-white'"
                        :buttonSize="'small'"
                        :customIcon="'trash'"
                        @click="removeImage(element.id)"
                    />
                    <Button class="ml-10"
                        :buttonLabel="cancelLabel"
                        :arrowLeft="false"
                        :arrowRight="false"
                        :buttonStyle="'no-outline-white'"
                        :buttonSize="'small'"
                        @click="toggleDeletePrompt(element.id)"
                    />
                </div>

            </div>
        </template>
    </draggable>

    <a href="#" class="upload-image__button" @click="triggerUpload">
        <Button
            :buttonLabel="addImageLabel"
            :arrowLeft="false"
            :arrowRight="false"
            :buttonStyle="'blue'"
        />
    </a>

    <div class="upload">

        <input
            @change="uploadImage"

            :id="inputId"
            ref="upload"
            type="file"
            :accept="inputAttributes.accept"
            hidden
        >
    </div>

</template>

<script>
import draggable from '@/plugins/VueDraggable/vuedraggable.common';
import Button from "@/components/ui-kit-elements/Button";

export default {
    name: 'input-upload',
    props: [
        'inputId',
        'inputRequired',
        'inputValue',
        'inputError',
        'inputHidden',
        'inputAttributes'
    ],
    components: {
        Button,
        draggable
    },
    data() {
        return {
            images: null,
            imagesArray: this.inputValue,
            updatedArray: [],
            descriptionLabel: `${description}`, // eslint-disable-line
            deleteLabel: `${deleteLabel}`, // eslint-disable-line
            cancelLabel: `${cancelLabel}`, // eslint-disable-line
            addImageLabel: `${addImage}` // eslint-disable-line
        }
    },
    methods: {

        /**
         * If the element should be hidden, hide it
         * Called in mounted
         */
        hidden() {
            this.$refs.input.hidden = true;
        },

        triggerUpload() {
            this.$refs.upload.click();
        },

        async uploadImage() {

            this.images = this.$refs.upload.files[0];

            const formData = new FormData();
            formData.append('files[]', this.images);

            const result = await this.$api.form.media(formData);

            this.updatedArray.push(result.data[0]);
            this.emitUpdate();
        },

        dragStart() {
            document.querySelector('[data-draggable]').classList.add('upload-image-wrapper--dragging');
        },

        dragEnd(event) {

            document.querySelector('[data-draggable]').classList.remove('upload-image-wrapper--dragging');

            // make new working copy of array
            let array = [];
            this.updatedArray.forEach(image => {
                array.push(image);
            });

            // re-order the array
            const element = array.splice(event.oldIndex, 1)[0];
            array.splice(event.newIndex, 0, element);
            this.updatedArray = array;

            this.emitUpdate();
        },

        findElementById(array, id) {
            return array.id === id;
        },

        /**
         * Delete an image
         */
        removeImage(imageId) {
            this.$refs[imageId].classList.add('no-display');

            const result = this.updatedArray.find( ({ id }) => id === imageId );
            this.imagesArray.splice(this.imagesArray.indexOf(result), 1);

            this.emitUpdate();
        },

        toggleDeletePrompt(imageId) {
            this.$refs['delete-' + imageId].classList.toggle('upload-image__delete-prompt--show');
        },

        /**
         * Emit validate-control event to trigger
         * The parent will listen for this event
         *
         * @param e
         */
        emitValidate(e) {
            this.$emit('validate-control', e);
        },

        /**
         * Emit update-input event to trigger
         * The parent will listen for this event
         *
         * @param data
         */
        emitUpdate(data = null) {
            // set our main array to our working copy
            this.imagesArray = this.updatedArray;

            // construct an object with the params we need to update the widget
            const update = {
                value: data ? data : this.updatedArray,
                id: this.inputId,
                dataset: null
            }

            this.$emit('update-input', update);
        },

        updateImageDescription(e, id) {
            const element = this.updatedArray.find(el => el.id === id);
            element.description = e.target.value;
            this.emitUpdate();
        }
    },
    mounted() {
        this.updatedArray = this.imagesArray;

        if (this.inputHidden) {
            this.hidden();
        }
    }
}
</script>
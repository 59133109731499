<template>

  <div class="autocomplete"></div>

</template>

<script>

export default {
  name: 'maps-autocomplete',
  data() {
    return {
      google: null,
    }
  },
  props: [
    'inputId',
  ],
  methods: {

    autoComplete() {
      const input = document.querySelector('#' + this.inputId);
      const options = {
        componentRestrictions: { country: "be" },
        types: ['(cities)'],
      };

      return new this.google.maps.places.Autocomplete(input, options);
    }
  },
  async mounted() {
    this.google = await this.$googleMaps;
    this.google.maps.event.addDomListener(document.querySelector('#' + this.inputId), 'keypress', this.autoComplete());
  },
}
</script>
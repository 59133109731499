<template>
  <div class="form-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-12">

          <Form
              :ajaxPath="'user/login'"
              :extraText="text"
              @post-success="redirect"
              @get-title="emitTitle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/form/Form";

export default {
  name: 'login-form',
  data() {
    return {
      text: null
    }
  },
  components: {
    Form
  },
  created() {
    this.ajaxGetText();
  },
  methods: {

    async ajaxGetText() {
      return await this.$api.text.get('user')
          .then(data => {

            this.text = data.data;

            // console.log(data)
          })
          .catch(error => {
            console.log(error);
          })
    },

    // redirect to next page and add necessary param userId
    redirect() {

      // let the application know we are now authenticated :-)
      this.$auth.isAuthenticated = '1';

      // go to our dashboard
      this.$router.push({ name: 'Dashboard' });
    },

    emitTitle(title) {

      this.$emit('get-title', title);
    }
  }
}
</script>
<template>

  <div class="input-edit">
    <span class="input-edit__edit" ref="inputEdit" @click="edit">
      <i class="icon-pencil"></i>
    </span>
    <span class="input-edit__revert input-edit__revert--hide" ref="inputRevert" @click="revert">
      <i class="icon-cancel"></i>
    </span>
  </div>

</template>

<script>
/**
 * Locked
 *
 * Can be used to 'lock' an input
 * When locked an input can be unlocked by using the content in this component
 */
export default {
  name: 'locked-input',
  data() {
    return {
      inputEdit: false,
      initialValue: this.inputValue,
      input: ''
    }
  },
  props: [
    'inputId',
    'inputValue'
  ],
  mounted() {
    this.input = document.querySelector('#' + this.inputId);
  },
  methods: {

    edit() {
      // don't do stuff when input is already changed
      if (this.inputEdit === true) return;

      this.inputEdit = !this.inputEdit;

      this.input.disabled = false;

      // hide edit button
      this.$refs.inputEdit.classList.add('input-edit__edit--hide');
      // show revert button
      this.$refs.inputRevert.classList.remove('input-edit__revert--hide');
    },

    revert() {
      // only do stuff if the input is unlocked
      if (this.inputEdit === false) return;
      this.inputEdit = false;

      this.input.value = this.initialValue;
      this.input.click();

      this.input.disabled = true; // disable input

      // show edit button
      this.$refs.inputEdit.classList.remove('input-edit__edit--hide');
      // show revert button
      this.$refs.inputRevert.classList.add('input-edit__revert--hide');
    }
  }
}
</script>
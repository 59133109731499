<template>

  <label @click="setActive" class="radio-label radio-label--checkbox" :for="inputId"
         :class="{ 'radio-label--active': active }">
    <span class="default-radio-icon"></span>
    <div v-html="inputHelp"></div>
  </label>

  <input
      @input="emitUpdate"
      class="hidden"

      :id="inputId"
      :required="inputRequired"
      value="false"
      type="checkbox"
      ref="input"
  >

  <p class="input-error">{{ inputError }}</p>

</template>

<script>
export default {
  name: 'Input-checkbox',
  data() {
    return {
      active: false
    }
  },
  mounted() {
    if (this.inputValue === true && this.$refs.input) {
      this.setActive();
    }
  },
  props: [
    'inputId',
    'inputRequired',
    'inputError',
    'inputHelp',
    'inputValue'
  ],
  methods: {

    setActive() {
      this.active = !this.active;
      this.$refs.input.value = this.$refs.input.value === 'false';
    },

    /**
     * Emit validate-control event to trigger
     * The parent will listen for this event
     *
     * @param e
     */
    emitValidate(e) {

      // construct an object with the params we need to validate the widget
      const validate = {
        value: e.target.value,
        id: this.inputId,
      }

      this.$emit('validate-control', validate);
    },

    /**
     * Emit update-input event to trigger
     * The parent will listen for this event
     *
     * @param e
     */ 
    emitUpdate(e) {

      // construct an object with the params we need to update the widget
      const update = {
        value: e.target.value === "true", // parse to boolean
        id: this.inputId,
        dataset: e.target.dataset
      }

      this.$emit('update-input', update);
    }
  }
}
</script>
<template>

<!--  <div v-if="error" class="form&#45;&#45;fail">-->
<!--    <h1>{{ error }}</h1>-->

<!--    <Button-->
<!--      :buttonStyle="'blue'"-->
<!--      :buttonLabel="'Terug'"-->
<!--      @click="this.$router.push({ name: 'Home' })"-->
<!--    />-->
<!--  </div>-->

  <div class="form" v-if="fetchFailed || (form && ajaxPath)">

      <div v-if="loading">
      <Loader />
    </div>

    <FormFailed v-if="fetchFailed" :fetchData="response" />

    <h2 class="form__title">{{ response.title }}</h2>
    <p class="form__description">{{ response.descr }}</p>

    <p v-if="form && form.error" class="form-error">{{ form.error }}</p>

    <form v-if="form" @submit.prevent="submitForm" :class="{ 'form--loading': loading }"
          class="form-group-wrapper">

      <div class="login-form__fields form-grid">
        <section v-for="widget in form.widgets" :key="widget.id"
             :class="'form-grid--' + widget.width">

          <Input @validate-control=""
                 @update-input="updateInput"
                 :v-model="formValues[widget.id]"

                 :inputId="widget.id"
                 :inputType="widget.type"
                 :inputValue="formValues[widget.id]"
                 :inputOptions="widget.formControl.selectOptions"
                 :inputLabel="widget.formControl.title"
                 :inputError="widget.formControl.error"
                 :inputHelp="widget.formControl.helpText"
                 :inputHidden="widget.formControl.hidden"
                 :inputAttributes="widget.attributes"
                 :inputRequired="widget.required"
          />

          <div v-if="extraText && widget.id === 'password'" class="login-form__fields__forgot-password">
            <router-link v-if="extraText.forgotPasswordLabel" :to="{ name: 'ForgotPassword', params: { lang: 'nl' } }">
              {{ extraText.forgotPasswordLabel }}
            </router-link>
          </div>
        </section>
      </div>

      <div class="form-group__buttons form__button-wrapper form__button-wrapper--login">

        <button class="form__button">
          <Button
              :buttonLabel="form.submitButtonLabel"
              :buttonStyle="'blue'"
              :arrowRight="true"
          />
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import Input from "@/components/form/inputs/Input";
import Loader from "@/components/layout/Loader";
import FormFailed from "@/components/form/FormFailed";

/**
 * Form.vue
 * This component generates the forms we receive from the backend
 */
export default {
  name: 'form-component',
  components: {
    FormFailed,
    Input,
    Loader
  },
  props: [
    'ajaxPath',
    'ajaxParams',
    'extraText',
    'propForm'
  ],
  data() {
    return {

      /**
       * The complete response data (not just the form)
       */
      response: null,


      /**
       * The form we load via ajax (only form to avoid enormous property chains ( thank me later! ;-) ))
       */
      form: null,

      /**
       * Not everything works out all the time, the same applies to forms
       * If we fail to fetch our form we can use this property to store an error to show to the user
       */
      error: null,

      /**
       * validateModel
       *
       * This property is used to do real-time validation
       * The values will be updated whenever a control is validated in validateControl(e)
       */
      validateModel: {
        widgetId: '',
        formValues: null
      },

      /**
       * formValues
       *
       * This property contains all the v-model values we need for the loaded form
       * This property will automatically be filled whenever the form is loaded in ajaxGetForm()
       */
      formValues: {},
      loading: true,

      fetchFailed: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  methods: {

    /**
     * Load the registration form via ajax
     */
    async ajaxGetForm() {

      return await this.$api.form.get(this.ajaxPath, this.ajaxParams)
          .then(data => {

            this.initForm(data);
          })
          .catch(error => {
            console.log(error);
          });
    },

    /**
     * Initialise the form
     */
    initForm(data) {
      this.response = data;

      if (data.code === 403) {

        this.fetchFailed = true;
        this.loading = false
        return;
      }

      if (data.form && data.success) {

        // fill formValues with the value of each widget
        data.form.widgets.forEach((item) => {

          this.formValues[item.id] = item.formControl.value;

          if (this.$route.params[item.id]) {
            this.formValues[item.id] = this.$route.params[item.id];
          }

          if (data.message) {
            this.error = data.message;
          }

          // handle the width of the widget
          this.handleWidgetWidth(item);
        });
      }
      else {
        this.error = data.message;
      }

      // set the form
      this.form = data.form;

      // console.log('Get successful');
      this.loading = false;

      this.$emit('get-title', data.title);
    },

    handleWidgetWidth(widget) {

      // set the widget width
      widget.width = 12;

      if (this.response.form.layout.widthContainer[widget.id]) {
        widget.width = this.response.form.layout.widthContainer[widget.id];
      }
    },

    /*
     * Validate a control when it loses focus (real time)
     *
     * This method performs a 'ping pong' with the backend to check if the value is valid
     * We do this to prevent submit errors (nobody likes submit errors)
     */
    async validateControl(id, value) {

      // set variables to the values from input
      const widgetId = id;
      const formValues = { [id]: value };

      // update the validateModel to send to backend with the variables we just made
      this.validateModel.widgetId = widgetId;
      this.validateModel.formValues = formValues;

      // post our validateModel to the validateControl route for this form
      const result = await this.$api.form.validate(JSON.stringify(this.validateModel), this.ajaxPath);

      this.handleWidgetErrors(result, widgetId);

      return true;
    },

    handleWidgetErrors(data, id) {

      const element = document.querySelector('#' + id);

      // if we have an error add right classes, and vice-versa
      if (data[id].hasError) {
        element.classList.add('input--error');
      }
      else {
        element.classList.remove('input--error');
      }

      // add the error message (if we don't have one, nothing will render)
      element.nextSibling.innerText = data[id].error;

      this.handleStates(id);
    },

    /**
     * When we change the input, our v-model needs to change too
     * That's why we set the v-model equal to the value of the value of the input
     *
     * This method is called whenever the input changes, this way we can change the v-model in real-time
     */
    updateInput(id, inputValue, dataset) {

      // check if the target has an isObject attribute, if so, we need to parse it
      if (dataset !== null && dataset.isObject) {
        inputValue = JSON.parse(inputValue);
      }

      this.formValues[id] = inputValue;
    },

    /**
     * Submit the form
     *
     * @returns {Promise<*>}
     */
    async submitForm() {
      this.loading = true;

      return await this.$api.form.post(JSON.stringify({ 'formValues': this.formValues }), this.ajaxPath, this.ajaxParams)
          .then(data => {

            if (!data.success) {
              this.loading = false;

              // if we have errors we can replace our form with the form the backend sends back
              // (if did a good job this should never be the case, though :lol: )
              return this.form = data.form;
            }

            // console.log('Post successful');
            this.loading = false;


            // trigger message (if there is one)
            data.todos.forEach((todo) => {

              if (todo['type'] === 'message') {

                this.$store.dispatch('message/setMessage', todo['text']);
              }
            });

            // emit todos along with the post-success event, some forms need this response data for routing purposes
            this.$emit('postSuccess', data.todos);
          });
    }
  },
  created() {

    // sometimes we might have to load a form before loading this component (to access a translatable for example).
    // in this case we can also pass the form as a prop, this way we don't need to load the form twice.
    if (! this.propForm) {
      // load the form
      this.ajaxGetForm();
    }
    else {
      this.initForm(this.propForm);
    }
  }
}
</script>